/* popup1 */
.popUp1Container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.5);
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popUp1 {
  background-color: #8cc9e9;
  width: 500px;
  /* max-width: 90%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  padding: 60px 20px;
  position: relative;
}

.close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  font-family: "MontserratBold";
  font-size: 1.5rem;
  color: white;
}

.btnwrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.input {
  width: 90%;
  max-width: 20rem;
  height: 3.5rem;
  border-radius: 1.25rem;
  font-family: "MontserratRegular";
  color: #333333;
  text-align: center;
  background-color: #f4f4f4;
}

.btn {
  background-color: #333333;
  height: 3rem;
  width: 10rem;
  color: white;
  padding: 0rem 0.5rem;
  border-radius: 1.563rem;
  font-size: 0.8rem;
  font-family: "MontserratRegular";
  cursor: pointer;
}

.btn:hover {
  background-color: white;
  color: #333333;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .popUp1 {
    padding: 40px 15px;
  }

  .close {
    font-size: 1.2rem;
  }

  .btn {
    font-size: 0.7rem;
    padding: 0.8rem 1rem;
  }

  .form {
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .popUp1 {
    width: 90%;
  }

  .input {
    height: 3rem;
    font-size: 0.9rem;
  }

  .btnwrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
}

/* banner 1 */

/* Styling for the banner box */
.bannerBox {
  /* background-color: white; */
  width: 25rem;
  /* Default width for large screens */
  max-width: 90%;
  /* Ensures proper sizing on smaller screens */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  position: relative;
  /* For close button positioning */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  /* Adds a subtle shadow */
  overflow: hidden;
  /* Prevents content overflow */
}

/* Styling for the banner image */
.bannerImg {
  width: 100%;
  /* Full width for responsiveness */
  height: auto;
  /* Maintain aspect ratio */
  border-radius: 10px;
}

/* Styling for the close button */
.closeBanner1 {
  position: absolute;
  /* right: 2.5rem; */
  right: 1rem;
  top: 0.58rem;
  cursor: pointer;
  font-family: "MontserratBold";
  font-size: 1.5rem;
  color: white;
  z-index: 10;
  /* Ensures it appears above content */
}

/* Responsive styling for smaller devices */
@media screen and (max-width: 768px) {
  .bannerBox {
    width: 80%;
    /* Reduce the width for tablets and smaller devices */

    /* padding: 15px; */

    /* Adjust padding */
  }

  .close {
    font-size: 1.2rem;
    /* Adjust the size of the close button */
  }
}

@media screen and (max-width: 480px) {
  .bannerBox {
    width: 95%;
    /* Further reduce width for very small screens */
  }

  .bannerImg {
    border-radius: 5px;
    /* Reduce border radius for a more compact look */
  }
}

/* bannar 2 */

/* Banner2 styles */
.banner2 {
  background-color: #333333;
  width: 50%;
  /* Default width for larger screens */
  max-width: 90%;
  /* Ensures it doesn't overflow on smaller screens */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  /* Necessary for the close button positioning */
  /* transform: translate(-50%, -50%); */
}

/* Close button styles */
.closeb2 {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  font-family: "MontserratBold";
  font-size: 1.5rem;
  color: white;
  z-index: 10;
  /* Ensure the close button appears above other content */
}

/* Text and input styling */
.banner2Text {
  font-family: "MontserratMedium";
  font-size: 1rem;
  text-align: center;
  color: white;
  margin-bottom: 20px;
  width: 80%;
}

.banner2inputBox {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: center;
  margin: 20px 0;
}

.banner2input {
  width: 39%;
  height: 2.5rem;
  border-radius: 1.25rem;
  font-family: "MontserratRegular";
  text-align: center;
  background-color: #f4f4f4;
  color: #333333;
}

.banner2Input {
  width: 80%;
  height: 3rem;
  border-radius: 1.25rem;
  font-family: "MontserratRegular";
  text-align: center;
  background-color: #f4f4f4;
  color: #333333;
  margin-bottom: 20px;
}

.list {
  color: white;
  font-family: "MontserratRegular";
  margin: 20px 0;
  font-size: 0.8rem;
  text-align: left;
  width: 40%;
}

.banner2btn {
  background-color: white;
  color: #333333;
  padding: 10px 20px;
  border-radius: 1.5rem;
  font-family: "MontserratRegular";
  font-size: 0.8rem;
  cursor: pointer;
}

.banner2btn:hover {
  background-color: #333333;
  color: white;
  border: 1px solid #8cc9e9;
}

.banner2FooterText {
  text-align: center;
  color: white;
  font-size: 0.7rem;
  font-family: "MontserratRegular";
  margin-top: 1rem;
  width: 80%;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .banner2 {
    width: 70%;
    padding: 15px;
  }

  .banner2inputBox {
    flex-direction: column;
    gap: 1rem;
  }

  .banner2input,
  .banner2Input {
    width: 100%;
  }

  .banner2Text {
    font-size: 0.9rem;
  }

  .list {
    /* background-color: red; */
    width: 80%;
    font-family: "MontserratMedium";
  }

  .list li {
    font-size: 0.9rem;
  }
}

/* Responsive adjustments for very small screens */
@media screen and (max-width: 480px) {
  .banner2 {
    width: 95%;
    padding: 10px;
  }

  .banner2Text {
    font-size: 0.8rem;
    padding: 0 10px;
  }

  .banner2Input {
    width: 90%;
  }

  .list {
    /* background-color: red; */
    font-size: 1.5rem;
    margin: 10px 0;
  }

  .banner2btn {
    padding: 8px 15px;
    font-size: 0.7rem;
  }

  .closeb2 {
    font-size: 1.2rem;
  }

  /* This is coment */
}