.container {
  max-width: 1440px;
  margin: auto;
}


.errorMessage {
  color: red;
  font-size: 0.9rem;
  margin-top: -0.5rem;
  margin-left: 1rem;

}

.errorMessagedropdown {
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  margin-left: 1.5rem;
}


.errorAdDetials {
  color: red;
  margin-left: 0.5rem;
}

.form_pic_con {
  width: 80%;
  margin: auto;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 2rem 7.5rem; */
  padding: 0 7% 0 8%;
  box-sizing: border-box;
  text-align: center;
  margin-top: 5.6%;
}

.recaptcha_btn {
  margin-top: 45px;
}

.form_pic {
  height: 100%;
  width: 100%;
  background: url("../../../public/Images/formpic.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  padding: 6rem 0;
  text-align: center;
}

.form_pic p {
  font-size: 2.5rem;
  font-weight: 900;
  font-family: GalanoGrotesqueAlt-Bold;
}

#fpt2 {
  padding-top: 1rem;
}

#fpt3 {
  margin-top: 2%;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: GalanoGrotesque-Medium;
}

.form_pic section {
  padding-bottom: 3%;
  color: #d8d8d8;
}

.yourBenefits {
  width: 100%;
  margin-top: 5%;
  margin-bottom: 3%;
  text-align: center;
}

.yourBenefits p {
  text-align: center;
  font-size: 2rem;
  font-family: GalanoGrotesque-Medium;
  font-weight: 500;
}

.targetDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7fcff;
  padding: 0 10px;
  font-size: 1rem;
  font-family: GalanoGrotesque-Medium;
  color: gray;
  border-radius: 8px;
}

.targetDiv>span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background-color: #f7fcff;
}

.benefits {
  width: 100%;
}

.benefitsRowOne {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: auto;
}

.benefitCard {
  /* width: 14rem; */
  padding: 0.8rem 1.2rem !important;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  gap: 1rem;
  border-radius: 30px;
  margin-top: 2%;
}

.benefitCard p {
  white-space: nowrap;
}

.benefitCard img {
  width: 1.5rem !important;
  height: 1.5rem;
  margin-left: 3%;
}

.benefitsRowTwo {
  /* width: 80%; */
  display: flex;
  gap: 1rem;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 1rem;
}

.benefitCardTwo {
  padding: 0.8rem 1.2rem !important;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  gap: 1rem;
  border-radius: 30px;
  margin-top: 2%;
  white-space: unset !important;
}

.benefitCardTwo img {
  width: 1.5rem !important;
  height: 1.5rem;
  margin-left: 5%;
}

.benefitCardTwo p {
  white-space: inherit;
}

.benefitCard:hover {
  background-color: white;
  transition: ease-in-out;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 10px 20px 0px;
}

.benefitCardTwo:hover {
  background-color: white;
  transition: ease-in-out;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 10px 20px 0px;
}

.main_form {
  display: flex;
  justify-content: center;
  margin-top: 6%;
}

.sub_form {
  width: 80%;
  height: auto;
  margin: auto;
}

.location,
.legal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  justify-content: space-between;
  margin-top: 4rem;
}

.location_b,
.legal_b {
  margin-right: 9%;
}

.loc,
.l_r,
.im {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-family: GalanoGrotesque-Bold;
  color: #333333;
  /* border-bottom: 1px solid #333333; */
  padding-bottom: 0.2rem;
  width: 100%;
  /* display: inline; */
}

.headingMargin {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headingRight img {
  width: 50%;
}

.label {
  font-size: 1.2rem !important;
  font-family: GalanoGrotesque-Medium;
  color: #333333;
  margin-left: 5px;
  margin-top: 50px !important;
}

.form_field {
  height: 55px !important;
  width: 100%;
  /* padding-left: 8px; */
  border-radius: 8px;
  background: #f7fcff;
  border: 2px solid transparent;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: 'GalanoGrotesque-Medium';
  font-size: 0.9rem;
  text-indent: 14px;
}

.form_field::placeholder {
  font-size: 1rem;
  padding-right: 14px;
}

.form_field_date {
  height: 75px;
  width: 100%;
  padding-right: 10px;
  border-radius: 8px;
  background: #f7fcff;
  border: 2px solid transparent;
  margin-top: 1vw;
  font-family: GalanoGrotesque-Medium;
  font-size: 0.9rem;
  text-indent: 14px;
}

.form_field_date::placeholder {
  font-size: 1.2rem;
  padding-right: 14px;
}

.form_btn {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin-top: 1rem;
  margin-right: 5%;
}

.form_btn button {
  background: #333333;
  color: #ffffff;
  padding: 0.5vw 1vw;
  font-size: 1rem;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  font-family: GalanoGrotesque-Regular;
}

.marginField {
  margin-top: 0px;
}

.form_fieldTextArea {
  width: 100%;
  height: 225px;
  padding-left: 8px;
  margin-top: 1.5rem;
  border-radius: 8px;
  background: #f7fcff;
  resize: none;
  border: 2px solid transparent;
}

.form4_pic {
  height: 100%;
  width: 100%;
  background: url("../../../public/Images/form4pic.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  padding: 2rem 0 4rem 0;
  text-align: center;
}

.form4_pic img {
  width: 10%;
}

.f4_maint {
  font-size: 2.7rem;
  font-family: GalanoGrotesqueAlt-Bold;
  color: #ffffff;
  line-height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.f4_subt1 {
  font-size: 1.3rem;
  font-family: GalanoGrotesque-Medium;
  color: #d8d8d8;
  margin-top: 2%;
}

.f4_subt2 {
  font-size: 1.3rem;
  font-family: GalanoGrotesque-Medium;
  color: #d8d8d8;
}

.done {
  width: 70px;
  position: absolute;
  top: 23.5%;
}

.underLineBorder img {
  width: 99%;
}

.benefitCardTwoP {
  display: flex;
}







@media screen and (max-width: 767px) {
  .form_pic_con {
    /* padding: 0rem 3rem 0 5rem; */
    /* margin-top: 3%; */
    width: 100%;
  }

  .yourBenefits p {
    font-size: 6.5vw;
  }

  .targetDiv {
    font-size: 16px;
    white-space: nowrap;
    gap: 0px;
  }

  .targetDiv p {
    font-size: 16px;
  }

  .benefitsRowOne {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    margin: auto;
  }

  .benefitCard {
    /* width: 85%; */
    justify-content: center;
    align-items: center;
    padding: 2vw;
    padding-top: 4vw;
    padding-bottom: 4vw;
  }

  .benefitCard p {
    font-size: 1rem !important;
    /* margin-left: 15px; */
  }

  .benefitCard img {
    width: 10%;
    margin-left: 6%;
  }

  .benefitsRowTwo {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: auto;
    margin-top: 15px !important;
  }

  .benefitCardTwo {
    /* width: 85%; */
    justify-content: center;
    align-items: center;
  }

  .benefitCardTwo img {
    width: 10%;
    margin-left: 5%;
  }

  .benefitCardTwo p {
    width: 100% !important;
    font-size: 1rem !important;
    width: 100% !important;
    white-space: nowrap !important;
  }

  .loc,
  .l_r,
  .im {
    font-size: 5vw;
  }

  .headingMargin {
    width: 100%;
    justify-content: space-between;
  }

  .headingRight {
    display: flex;
    justify-content: flex-end;
  }

  .headingRight img {
    width: 48%;
    margin-top: -6%;
  }

  .location,
  .legal {
    grid-template-columns: 1fr;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
  }

  .location_b,
  .legal_b {
    margin-right: 0;
  }

  .label {
    font-size: 1rem !important;
    margin-left: 5px;
    margin-top: 50px !important;
  }

  .form_field {
    height: 50px;
    /* font-size: 4vw; */
    font-size: 16px;
  }

  .form_field_date {
    height: 50px;
  }

  .form_field::placeholder {
    font-size: 16px;
  }

  .form_btn {
    margin-top: 30px;
  }

  .form_btn button {
    /* width: 50%; */
    padding: 10px 15px !important;
    font-size: 0.8rem !important;
    font-family: GalanoGrotesque-Regular;
  }

  .done {
    width: 9vw;
    top: 27vw;
  }

  .underLineBorder img {
    width: 84%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

  /* .form_pic_con {
    padding: 0rem 3rem 0 5rem;
    margin-top: 3%;
    width: 90%;
  } */
  .form_pic {
    border-radius: 30px;
    padding: 6rem 0;
  }

  .form_pic p {
    font-size: 1.8rem;
  }

  #fpt2 {
    padding-top: 1rem;
  }

  #fpt3 {
    margin-top: 2%;
    font-size: 0.8rem;
  }

  .yourBenefits p {
    font-size: 1.8rem;
  }

  .benefitsRowOne {
    width: 90%;
    gap: 3%;
  }

  .benefitCard {
    width: 28%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    gap: 5%;
    border-radius: 15px;
  }

  .benefitCard img {
    width: 15%;
    margin-left: 3%;
  }

  .benefitCard p {
    font-size: 0.7rem;
  }

  .benefitsRowTwo {
    width: 82%;
    gap: 2%;
  }

  .benefitCardTwo {
    width: 49%;
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
    gap: 3%;
    margin-top: 2%;
    border-radius: 15px;
  }

  .benefitCardTwo img {
    width: 8.8%;
    margin-left: 3%;
  }

  .benefitCardTwo p {
    font-size: 0.7rem;
    /* white-space: nowrap; */
  }

  .location,
  .legal {
    gap: 60px;
  }

  .location_b,
  .legal_b {
    margin-right: 10%;
  }

  .loc,
  .l_r,
  .im {
    margin-top: 1rem;
    font-size: 1.4rem;
    padding-bottom: 0.2rem;
  }

  .label {
    font-size: 1.35rem;
    margin-left: 5px;
    margin-top: 50px !important;
  }

  .form_field {
    height: 75px;
    padding-left: 8px;
    margin-top: 1vw;
    margin-bottom: 1.5vw;
    font-size: 0.9rem;
  }

  .form_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
  }

  .form_btn button {
    padding: 1rem 1.4rem;
    font-size: 1rem;
  }

  .marginField {
    margin-top: 0px;
  }

  .form_fieldTextArea {
    width: 100%;
    padding-left: 8px;
    border-radius: 8px;
    background: #f7fcff;
    border: 2px solid transparent;
  }

  .form4_pic {
    border-radius: 30px;
    padding: 2rem 0 4rem 0;
  }

  .form4_pic img {
    width: 10%;
  }

  .f4_maint {
    font-size: 1.8rem;
    line-height: 2rem;
  }

  .f4_subt1 {
    font-size: 0.8rem;
    margin-top: 2%;
  }

  .f4_subt2 {
    font-size: 1rem;
  }

  .done {
    width: 60px;
    top: 21.5%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {

  /* .form_pic_con {
    padding: 0rem 3rem 0 5rem;
    margin-top: 3%;
  } */
  .form_pic {
    border-radius: 30px;
    padding: 6rem 0;
  }

  .form_pic p {
    font-size: 1.8rem;
  }

  #fpt2 {
    padding-top: 1rem;
  }

  #fpt3 {
    margin-top: 2%;
    font-size: 0.8rem;
  }

  .yourBenefits p {
    font-size: 1.8rem;
  }

  .benefitsRowOne {
    width: 90%;
    gap: 3%;
  }

  .benefitCard {
    width: 29%;
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
    gap: 5%;
  }

  .benefitCard img {
    width: 15%;
    margin-left: 3%;
  }

  .benefitCard p {
    font-size: 1rem;
  }

  .benefitsRowTwo {
    width: 82%;
    gap: 5%;
  }

  .benefitCardTwo {
    width: 49%;
    padding-top: 1.9rem;
    padding-bottom: 1.9rem;
    gap: 5%;
    margin-top: 2%;
  }

  .benefitCardTwo img {
    width: 9.6%;
    margin-left: 3%;
  }

  .benefitCardTwo p {
    font-size: 0.93rem;
  }

  .location,
  .legal {
    gap: 60px;
  }

  .location_b,
  .legal_b {
    margin-right: 10%;
  }

  .loc,
  .l_r,
  .im {
    margin-top: 1rem;
    font-size: 1.5rem;
    padding-bottom: 0.2rem;
  }

  .label {
    font-size: 1.35rem;
    margin-left: 5px;
    margin-top: 50px !important;
  }

  .form_field {
    height: 75px;
    padding-left: 8px;
    margin-top: 1vw;
    margin-bottom: 1.5vw;
    font-size: 0.9rem;
  }

  .form_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
  }

  .form_btn button {
    padding: 1rem 1.8rem;
    font-size: 1.2rem;
  }

  .marginField {
    margin-top: 0px;
  }

  .form_fieldTextArea {
    width: 100%;
    padding-left: 8px;
    border-radius: 8px;
    background: #f7fcff;
    border: 2px solid transparent;
  }

  .form4_pic {
    border-radius: 30px;
    padding: 2rem 0 4rem 0;
  }

  .form4_pic img {
    width: 10%;
  }

  .f4_maint {
    font-size: 1.8rem;
    line-height: 2rem;
  }

  .f4_subt1 {
    font-size: 0.8rem;
    margin-top: 2%;
  }

  .f4_subt2 {
    font-size: 1rem;
  }

  .done {
    width: 80px;
    top: 23.5%;
  }
}