.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.188rem;
}

.image {
    width: 6.438rem;
    height: 6.438rem;
    margin-bottom: 2.188rem;
}

.title {
    color: #8CC9E9;
    font-family: 'GalanoGrotesque-SemiBold';
    font-size: 1.5rem;
    margin-bottom: 0.625rem;
}

@media screen and (max-width: 500px) {
    .title {
        color: #8CC9E9;
        font-family: 'GalanoGrotesque-SemiBold';
        font-size: 1.5rem;
        margin-bottom: 0.625rem;
        text-align: center;
    }
}