.container{
  max-width: 1440px;
  margin: auto;
}
.footerContainer {
  padding: 0px 120px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  margin-top: 4rem;
}
.footer_pic {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0rem;
}

.footer_pic > img {
  width: 4%;
}
.footer_txt {
  width: 100%;
  /* margin: auto; */
  margin-top: 1rem;
  margin-bottom: 1rem;

  display: flex;
  justify-content: center;
}
.footer_txt img {
  height: auto;
  width: 2%;
  margin-top: 0.3%;
  margin-left: 1rem;
  margin-right: 1rem;
}
.footer_txt span {
  /* width: 0%; */
  font-size: 1rem;
  font-weight: 700;
  color: #333333;
  font-family: 'GalanoGrotesque-SemiBold';
}
.footer_frame {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 20%; */
  height: 38px;
  margin: auto;
  gap: 10px;

}

.footer_items {
  width: 100%;
  margin: auto;
  margin-top: 0.75rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}
a{
  padding: 0px !important;
}
.menu {
  width: 30px;
  font-size: 1rem;
  font-family: 'GalanoGrotesque-SemiBold';
  color: #333333;
}
.copyright {
  text-align: center;
  color: #333333;
  font-size: 0.5rem;
  font-family: 'GalanoGrotesque-Medium';
  line-height: 0.75rem;
  margin-top: 2%;
  height: 2rem;
}

@media screen and (max-width:767px){
  .footerContainer {
    padding: 0px 10px;
    margin-top: 2rem;
  }

  .footer_pic > img {
    width: 25%;
  }
  .footer_txt{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .footer_txt img {
    margin-left: 10px;
    margin-right: 10px;
    width: 1rem;
    margin-top: 1.5%;    
  }

  .footer_txt span {
    width: 100%;
    font-size: 1rem;
    text-align: right;
  }
  .spanRight{
    text-align: left !important;
  }
  .footer_frame {
    display: flex;
    justify-content: center;
    margin-top: 0%;
    gap: 0px;
  }
 
  /* .footer_frame a{
    width: 16%;
    margin-left: 1rem;
    margin-right: 1rem;
} */
    
  .footer_items {
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 3rem;
    text-align: center;
  }
  .menu {
    min-width:0px ;
    font-size: .8rem;
    color: #333333;
    padding-left: 0%;
  }
  .copyright {
    text-align: center;
    color: #333333;
    font-size: 0.8rem;
    line-height: 0rem;
    margin-top: 6%;
  }
}
/* 
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .footerContainer {
    padding: 0px 120px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
  .footer_pic {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }

  .footer_pic > img {
    display: inline-block;
    width: 6%;
  }
  .footer_txt img {
    margin-left: 0.4%;
    margin-right: 0.2%;
    height: 100%;
    width: 3%;
    margin-top: 0%;
    
  }
  .footer_txt span {
    width: 12.5%;
    font-size: 0.8rem;
    font-weight: 700;
    color: #333333;
    font-family: 'GalanoGrotesque-SemiBold';

  }
  .footer_frame {
    display: flex;
    justify-content: center;
    margin-top: 0%;
  }

  .footer_items {
    width: 80%;
    margin: auto;
    margin-top: 0.75rem;
    text-align: center;
  }
  .menu {
    font-size: 1rem;
    font-weight: 700;
    color: #333333;
    margin-left: 1%;
  }
  .copyright {
    text-align: center;
    color: #333333;
    font-size: 0.6rem;
    line-height: 0rem;
    margin-top: 2%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .footer_txt img {
    height: 100%;
    width: 2.5%;
    margin-top: 0.3%;
  }
  .footer_txt span {
    width: 11%;
    font-size: 1rem;
    font-weight: 700;
    color: #333333;
  }
  .footer_frame {
    display: flex;
    justify-content: center;
    margin-top: 0.75rem;
  }

  .footer_items {
    width: 90%;
    margin: auto;
    margin-top: 0.75rem;
    text-align: center;
  }
  .menu {
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    color: #333333;
    margin-left: 1.5%;
  }
  .copyright {
    text-align: center;
    color: #333333;
    font-size: 0.8rem;
    line-height: 0.75rem;
    margin-top: 1.5%;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1439px) {
  .footer_txt img {
    margin-left:-0.5%;
    margin-right:-0.5%;
    height: 100%;
    width: 1.9%;
    margin-top: 0.3%;
  }
  .footer_txt span {
    width: 10%;
    font-size: 1.1rem;
    font-weight: 700;
    color: #333333;
  }
  .footer_frame {
    display: flex;
    justify-content: center;
    margin-top: 0.9rem;
  }

  .footer_items {
    width: 98%;
    margin: auto;
    margin-top: 0.9rem;
    text-align: center;
  }
  .menu {
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    color: #333333;
    margin-left: 3%;
  }
  .copyright {
    text-align: center;
    color: #333333;
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-top: 1.5%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 4000px) {
  .footerContainer {
      padding: 0px 120px;
      width: 100%;
      box-sizing: border-box;
      text-align: center;
    }
  
    .footer_pic {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 4rem;
    }
  
    .footer_pic>img {
      display: inline-block;
      width: 6%;
    }
  
    .footer_txt img {
      margin-left: 0.4%;
      margin-right: 0.2%;
      height: 100%;
      width: 3%;
      margin-top: 0.4%;
  
    }
  
    .footer_txt span {
      width: 12.5%;
      font-size: 0.8rem;
      font-weight: 700;
      color: #333333;
    }
  
    .footer_frame {
      display: flex;
      justify-content: center;
      margin-top: 0.75rem;
    }
  

  
    .footer_items {
      width: 80%;
      margin: auto;
      margin-top: 0.75rem;
      text-align: center;
    }
  
    .menu {
      font-size: 1rem;
      font-weight: 700;
      color: #333333;
      margin-left: 1%;
    }
  
    .copyright {
      text-align: center;
      color: #333333;
      font-size: 0.6rem;
      line-height: 0.75rem;
      margin-top: 2%;
    }
} */

/* @media screen and (min-width: 1981px){
  .footer_txt img {
    margin-left:-1%;
    margin-right:-1%;
    height: 100%;
    width: 2%;
    margin-top: 0.15%;
  }
  .footer_txt span {
    width: 10%;
    font-size: 2rem;
    font-weight: 700;
    color: #333333;
  }
  .footer_frame {
    display: flex;
    justify-content: center;
    margin-top: 0.9rem;
  }
  .footer_frame img {
    width: 10%;
  }
  .footer_items {
    width: 95%;
    margin: auto;
    margin-top: 0.9rem;
    text-align: center;
  }
  .footer_items span {
    font-size: 2.2rem;
    font-family: Helvetica;
    font-weight: 700;
    color: #333333;
    margin-left: 3%;
  }
  .copyright {
    text-align: center;
    color: #333333;
    font-size: 2rem;
    font-family: GalanoGrotesque-Regular;
    line-height: 1rem;
    margin-top: 1.5%;
  }
} */
