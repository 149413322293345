/* Main Container */
.aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
}

.memberPhoto {
    width: 250px;
    height: auto;
    clip-path: circle(44% at 50% 50%);
    /* object-fit: contain;  */

}

/* Header */
.aboutHeader {

    background-color: #333333;
    color: #fff;
    text-align: center;
    padding: 12px 89px;
    border-radius: 1rem;
    margin-bottom: 55px;
    margin-top: 5rem;
    width: 90%;
    max-width: 612px;
    font-family: 'GalanoGrotesque-Bold';
    font-size: 1.5rem;

}

.aboutHeader h2 {
    font-size: 1.5rem;
    margin: 0;
}

/* Profile Image */
.profileImage {
    width: 350px;
    height: 350px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 75px;
}

.profileImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Quote Section */
.quoteSection {
    text-align: center;
    color: #4a4a4a;
    font-size: 1rem;
    line-height: 1.6;
    padding: 0 20px;
    width: 90%;
    max-width: 600px;
    margin-bottom: 3rem;
    font-family: 'GalanoGrotesque-Medium';

}

/* Download Button */
.downloadButton {
    margin-top: 30px;
}

.downloadButton button {
    background-color: #000000;
    color: #8CC9E9;
    font-size: 1.5rem;
    padding: 14px 50px;
    border: none;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'GalanoGrotesque-Bold';

}

.downloadButton button:hover {
    background-color: #555;
}

/* Responsive Design */
@media (max-width: 680px) {
    .aboutHeader {

        background-color: #333333;
        color: #fff;
        text-align: center;
        padding: 4px 4px;
        border-radius: 1rem;
        margin-bottom: 55px;
        margin-top: 5rem;
    }

    .downloadButton button {
        font-size: 1.125rem;
    }

}