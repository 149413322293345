.main {
    display: flex;
    justify-content: center;
    gap: 5rem;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.title {
    font-size: 1.5rem;
    font-family: 'GalanoGrotesque-SemiBold';
    color: #8CC9E9;
    text-align: center;
}

.box {
    width: 16rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    /* background-color: aqua; */
}

.connect {
    width: 8rem;
    height: 8rem;
}

.discover {
    width: 15rem;
    height: 9rem;
}

.meet {
    width: 13rem;
    height: 10rem;
}
.reward{
    width: 20rem;
    height: 10rem; 
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .title {
        font-size: 1.7rem;
        color: #8CC9E9;
    }

    .box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 1.2rem;
    }

    .connect {
        width: 8rem;
        height: 8rem;
    }

    .discover {
        width: 15rem;
        height: 9rem;
    }

    .meet {
        width: 13rem;
        height: 10rem;
    }
}

@media screen and (max-width:767px) {
    .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.875rem;
    }
}