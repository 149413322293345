.container {
  max-width: 1460px !important;
  margin: auto;
}

.header {
  width: 100%;
  min-height: 10vh;
  display: flex;
  background-color: #f2f2f2;
}

.header2 {
  width: 100%;
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  /* background-color: aqua; */
  /* padding-right: 3rem; */
  margin-top: 1rem;

}

.logo {
  width: 20%;
}

.logo img {
  width: 24%;
  margin-left: 1.8rem;
  /* margin-top: 8%; */
}

.logo img:hover {
  cursor: pointer;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 2rem;
  margin-top: 0.6rem;
  /* padding-right: 10%; */
  position: relative;
  /* background-color: rgb(255, 30, 0); */
}

/* .menu span {
  width: 100%;
  padding: 5px;
  background: red;
  font-size: 1.1rem;
  font-family: GalanoGrotesque-Bold;
}

.menu span:hover{
   border-bottom: 4px solid black;
   background-color: #333333;
   border-radius: 15px;
}
 */

.menu p {
  position: absolute;
  top: -0.5rem;
  /* left: 8px; */
  /* background-color: rgb(94, 255, 0, 50%); */
  /* height: 40px; */
  /* width: 65px; */
  border-radius: 30px;
  transition: 0.2s;
  z-index: -1;
  /* background-color: aqua; */
}

.menu :nth-child(1):hover ~ p {
  left: 5px;
  width: 95px;
  position: absolute;
  background-color: #e5e7eb;
  height: 40px;
  border-radius: 30px;
}

/* .menu :nth-child(2):hover~p{
  left: 85px;
  width: 95px;
  position: absolute;
  background-color: #e5e7eb;
  height: 40px;
  border-radius: 30px;
 } */

.menu :nth-child(2):hover ~ p {
  left: 105px;
  width: 60px;
  position: absolute;
  background-color: #e5e7eb;
  height: 40px;
  border-radius: 30px;
}

.menu :nth-child(3):hover ~ p {
  left: 170px;
  width: 78px;
  position: absolute;
  background-color: #e5e7eb;
  height: 40px;
  border-radius: 30px;
}

.menu :nth-child(4):hover ~ p {
  left: 250px;
  width: 173px;
  position: absolute;
  background-color: #e5e7eb;
  height: 40px;
  border-radius: 30px;
}

.menu span {
  /* width: 60px; */
  padding: 8px 15px;
  font-size: 1.1rem;
  /* border-radius: 30px; */
  font-family: "GalanoGrotesque-SemiBold";
}

/* .menu span:hover { */
/* background-color: #e5e7eb; */
/* color: #333333; Optional for better visibility */
/* border-radius: 30px; */
/* transform: translate(-100px); */
/* border-bottom: 4px solid black; Add a border on hover */
/* } */

/* .menu .activeMenu {
  background-color: #e5e7eb;
  color: #333333;
  border-radius: 30px;
 
} */

/* Add transition for background switch when hovering between menu items */
/* .menu span:not(:hover) { */
/* transition: background-color 0.3s ease, transform 0.3s ease; */
/* transform: none; Optional: Slightly enlarge the item on hover */
/* transform-origin: 50% 50% 0px; */

/* } */

.spanOne {
  /* margin-left: 40%; */
}

.menuSpan {
  /* margin-left: 3%; */
}

#become {
  /* border-bottom: 4px solid black; */
}

.activeMenu {
  /* word-spacing: 5px; */
  /* background-color:  #e5e7eb; */
  /* text-decoration: underline;  */
  /* text-decoration-color: black;  */
  /* text-decoration-thickness: 2px;  */
  /* text-underline-offset: 10px;  */
  /* border-radius: 5px;  */
  /* color: white; */

  /* Highlight color ; */
  /* border-bottom: 4px solid black;  */
  /* Optional border ; */
  /* text-underline-offset: 10px solid black;  */
}

.btnBox {
  width: 12rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .menuButton {
  padding: 10px;
  height: 45px;
  background-color: #333333;
  color: #ffffff;
  border-radius: 5px;
  font-family: 'GalanoGrotesque-Medium';
  font-size: 0.9rem;
}

.menuButton:hover {
  text-align: center;
  height: 45px;
  font-size: 1rem;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
} */

.menuButton {
  padding: 10px 20px;
  /* Adds horizontal padding for a better shape */
  height: 45px;
  background-color: #333333;
  color: #ffffff;
  border-radius: 8px;
  /* Slightly increased for a smoother curve */
  font-family: "GalanoGrotesque-Medium", sans-serif;
  font-size: 0.9rem;
  border: none;
  /* Removes default border for a cleaner look */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Adds subtle shadow for depth */
  cursor: pointer;
  /* Changes cursor to pointer for better UX */
  transition: all 0.3s ease-in-out;
  /* Smooth transition for all effects */
}

.menuButton:hover {
  background-color: #444444;
  /* Slightly lighter for a hover effect */
  color: #e0e0e0;
  /* Softer text color for hover */
  transform: scale(1.05);
  /* Subtle zoom effect */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  /* More pronounced shadow on hover */
}

.menu.active {
  display: flex;
}

.menuToggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 20px;
  z-index: 10;
  -webkit-tap-highlight-color: transparent;
}

.menuToggle .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
}

.menuButtonMobile {
  display: none;
}

.container1 {
  display: none;
}

.mobileMenuActive {
  display: none;
}

.mobileMenu {
  display: none;
}

@media (max-width: 920px) {
  .mobileMenuActive {
    display: none;
  }

  .header2 {
    width: 100%;
    min-height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: aqua; */
    padding-right: 0rem;
  }
}

@media (max-width: 900px) {
  .mobileMenuActive {
    display: none;
  }

  .menu span {
    padding: 8px 10px;
    font-size: 1rem;
  }

  .menuButton {
    margin-left: 25px;
  }

  /* .menuButtonMobile{
    display: block
  } */
  /* .btnBox{
    display: none;
  } */
  .menuButtonMobile {
    /* width: ; */
    padding: 10px;
    /* margin-left: 30px; */
    height: 45px;
    background-color: #333333;
    color: #ffffff;
    border-radius: 5px;
    font-family: "GalanoGrotesque-Medium";
    font-size: 0.9rem;
  }
}

/* Media query for smaller screens */
@media (max-width: 767px) {
  .container {
    display: none;
  }

  .header {
    width: 100%;
    min-height: 20vw;
    display: flex;
    background-color: #f2f2f2;
  }

  .header2 {
    width: 100%;
    min-height: 20vw;
    display: flex;
    background-color: white;
  }

  .menu {
    height: 100vh;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    background-color: #f2f2f2;
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 1;
    -webkit-tap-highlight-color: transparent;
  }

  menu:active,
  menu:focus {
    outline: none;
    /* Remove the outline (focus) style */
    color: inherit;
    /* Inherit the color from the parent element */
    text-decoration: none;
    /* Remove any text decoration (underline, etc.) */
    background-color: none;
  }

  .logo {
    width: 100%;
    height: 20vw;
  }

  .logo img {
    width: 13%;
    margin-top: 2%;
    margin-left: 8%;
  }

  .menu.active {
    display: flex;
  }

  .menuToggle {
    display: flex;
  }

  .menu span {
    width: 100%;
    font-size: 1rem;
    font-family: "GalanoGrotesque-SemiBold";
    margin-bottom: 10px;
    cursor: pointer;
  }

  .spanOne {
    text-align: center;
    margin-left: 0;
    margin-top: 15%;
    padding: 3vw;
    font-family: "GalanoGrotesque-SemiBold";
  }

  .menuSpan {
    text-align: center;
    margin-left: 0;
    padding: 3vw;
    font-family: "GalanoGrotesque-SemiBold";
  }

  a {
    text-align: center;
    margin-left: 0;
    padding: 3vw;
  }

  .menuButton {
    margin: auto;
    margin-top: 10px;
    width: 70%;
  }

  .menuButtonMobile {
    display: block;
  }

  .btnBox {
    display: none;
  }

  .container1 {
    display: block;
    height: 65px;
  }

  .mobileMenuActive {
    display: block;
    height: auto;
    padding-bottom: 3rem;
  }

  .mobileMenuDeactive {
    display: none;
  }

  .mobileMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}
.languageSelector {
  /* margin-left: 20px; */
  display: flex;
  align-items: center;
}

.languageSelector {
  font-size: 1rem;
  padding: 8px;
  border-radius: 5px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 15px;
}

/* Hover effect for language select */
.languageSelector :hover {
  background-color: #e5e7eb;
}

/* For mobile responsiveness */
@media (max-width: 920px) {
  .languageSelector {
    margin-left: 0;
    margin-right: 10px;
  }

  .languageSelector select {
    font-size: 1rem;
    padding: 8px;
    width: auto; /* Make it fit within the layout */
  }
}

@media (max-width: 767px) {
  /* Adjust mobile styles */
  .languageSelector {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .languageSelector select {
    width: 70%;
    font-size: 1rem;
    padding: 10px;
  }
}
