.container {
  max-width: 1440px;
  margin: auto;
}  
  .form_pic_con {
    width: 80%;
    position: relative;
    margin: auto;
  }

  .done {
    width: 70px;
    position: absolute;
    top: -4px; 
    left: 50%; 
    transform: translate(-50%, -50%);
  }

  
  .form4_pic {
      height: 100%;
      width : 100%;
      background: url("../../../public/Images/form4pic.png");
      background-size: cover;
      background-repeat: no-repeat;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 30px;
      padding: 2rem 0 4rem 0;
      text-align: center;
    }
  
    .ex{
      color: black;
      opacity: 0;
    }
  
    .form4_pic img{
      width: 10%;
    }
    .f4_maint{
      font-size: 2.7rem;
      font-family: GalanoGrotesqueAlt-Bold;
      color:#ffffff;
      line-height: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1%;
    }
    .f4_subt1{
      font-size: 1.3rem;
      font-family:GalanoGrotesque-Medium;
      color: #D8D8D8;
      margin-top: 5%;
    }
  
    .f4_subt2{
      font-size: 1.3rem;
      font-family:GalanoGrotesque-Medium;
      color: #D8D8D8;
      margin-top: 1%;
    }

@media screen and (max-width:767px){
  .done{
    width: 10vw;
  }
  .form_pic_con{
    width: 90%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {


.done{
  width: 60px;
  top: 21.5%;
}
.form4_pic {
  border-radius: 30px;
  padding: 0rem 0 3rem 0;
  }

  .form4_pic img{
    width: 10%;
    padding-top: 1rem;
  }
  .f4_maint{
    font-size: 1.5rem;
    margin-top: 1%;
  }
  .f4_subt1{
    font-size: 0.7rem;
    margin-top: 5%;
  }

  .f4_subt2{
    font-size: 0.7rem;
    margin-top: 1%;
  }

}


@media screen and (min-width: 1024px) and (max-width: 1365px) {
  /* .form_pic_con {
    padding: 0 3.5rem;
  } */
  
  .form4_pic {
    border-radius: 30px;
    padding: 2rem 0 3rem 0;
    }
  
    .form4_pic img{
      width: 10%;
      padding-top: 1rem;
    }
    .f4_maint{
      font-size: 1.8rem;
      margin-top: 1%;
    }
    .f4_subt1{
      font-size: 1rem;
      margin-top: 5%;
    }
  
    .f4_subt2{
      font-size: 1rem;
      margin-top: 1%;
    }
  
}

@media screen and (min-width: 1366px) and (max-width: 1439px) {
  /* .form_pic_con {
    padding: 0 6rem;
  } */
  
  .form4_pic {
    border-radius: 30px;
    padding: 2rem 0 3rem 0;
    }
  
    .form4_pic img{
      width: 10%;
      padding-top: 1rem;
    }
    .f4_maint{
      font-size: 2.5rem;
      margin-top: 1%;
    }
    .f4_subt1{
      font-size: 1.2rem;
      margin-top: 3%;
    }
  
    .f4_subt2{
      font-size: 1.2rem;
      margin-top: 1%;
    }
  
}

@media screen and (min-width: 1440px) and (max-width: 1840px) {
  /* .form_pic_con {
    padding: 0 4.5rem;
  } */
  
  .form4_pic {
    border-radius: 30px;
    padding: 2rem 0 3rem 0;
    }
  
    .form4_pic img{
      width: 10%;
      padding-top: 1rem;
    }
    .f4_maint{
      font-size: 2.5rem;
      margin-top: 1%;
    }
    .f4_subt1{
      font-size: 1.2rem;
      margin-top: 3%;
    }
  
    .f4_subt2{
      font-size: 1.2rem;
      margin-top: 1%;
    }
  
}

@media screen and (min-width: 1841px) and (max-width: 2240px) {
  /* .form_pic_con {
    padding: 0 7.5rem;
  }
   */
  .form4_pic {
    border-radius: 44px;
    padding: 2rem 0 5rem 0;
    }
  
    .form4_pic img{
      width: 10%;
      padding-top: 1rem;
    }
    .f4_maint{
      font-size: 3.5rem;
      margin-top: 1%;
    }
    .f4_subt1{
      font-size: 1.6rem;
      margin-top: 3%;
    }
  
    .f4_subt2{
      font-size: 1.6rem;
      margin-top: 1%;
    }
  
}

@media screen and (min-width: 2240px) {
  /* .form_pic_con {
    padding: 0 12rem;
  } */
  
  .form4_pic {
    border-radius: 70px;
    padding: 2rem 0 8rem 0;
    }
  
    .form4_pic img{
      width: 10%;
      padding-top: 1rem;
    }
    .f4_maint{
      font-size: 4.5rem;
      margin-top: 1%;
    }
    .f4_subt1{
      font-size: 2rem;
      margin-top: 3%;
    }
  
    .f4_subt2{
      font-size: 2rem;
      margin-top: 1%;
    }
  
}

