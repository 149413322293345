.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date{
    color: black;
    font-family: 'GalanoGrotesque-Medium';
    font-size: 1.5rem !important;
    margin-top: -.8rem;
}
.vertical-timeline-element-content {
  box-shadow: 0 0 0 #dddddd00 !important;
}

/* ////////////////////// */

.title {
  color: #333333;
  font-family: 'GalanoGrotesque-SemiBold';
  font-size: 2.188rem;
  margin-bottom: 3rem;
  margin-top: 2rem;
  text-align: center;
}
.btn{
  margin-top: 12rem;
}
.box1 {
    display: flex;
    justify-content: center;
    align-items: center;
}
.internalBox{
    display: flex;
    justify-content: center;
    align-items: center;
}
.circlesText {
    width: 80%;
  font-size: 1.125rem;
  font-family: 'GalanoGrotesque-Medium';
  text-align: center;
  margin-bottom: .5rem;
  margin-top: 0rem;
}


.circles1 {
    width: 13rem;
    height: 13rem;
    background-color: #8CC9E9;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   margin-right: -10px;
}
.circles2 {
  width: 13rem;
  height: 13rem;
  background-color: #8CC9E9;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 margin-left: 3px;
}
.circles4 {
  width: 11rem;
  height: 11rem;
  background-color: #8CC9E9;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#unique-container .vertical-timeline-element--work::after {
    content: none;
  }

.vertical-timeline-element--work .vertical-timeline-element::after{
    content: none;
}
.vertical-timeline-element-content.bounce-in::after {
    background-color: #ec2308 !important;

  }

  .circles2 {
    animation: scaleUp 1.5s ease-in-out infinite; /* Example animation */
  }
  .circles1 {
    animation: scaleUp 1.5s ease-in-out infinite; /* Example animation */
  }
 .box1{
 display: flex;
 align-items: center;
  flex-direction: column;
 }
 .box2{
  display: none;
 }
  
  @keyframes scaleUp {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  .circles2 img {
    animation: none;
  }

 .phone{
  height:auto;
  width:250px;
 }
 .phone2{
  height:auto;
  width:220px;
 }
 .phone3{
  height:auto;
  width:230px;
 }
 .svg{
  height:auto;
  width:40px;
 }

@media screen and (max-width:1169px) {
  .vertical-timeline--animate .vertical-timeline-element-content{
    border-top-right-radius:100px;
    border-bottom-left-radius:100px;

    border-bottom-right-radius:0px !important;
    border-top-left-radius:0px !important;
  }
  .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date{
    margin-top: -.8rem;
    margin-left: 2rem;
}
.phone{
  height:auto;
  width:160px;
 }
 .phone2{
  height:auto;
  width:160px;
 }
 .phone3{
  height:auto;
  width:160px;
 }
 .circlesText {
    width: 85%;
  font-size: 1rem;
  font-family: 'GalanoGrotesque-Medium';
  text-align: center;
  margin-bottom: .5rem;
}
.circles1 {
    width: 11rem;
    height: 11rem;
   margin-right: 3px;
}
.circles2 {
  width: 11rem;
  height: 11rem;
 margin-left: 5px;
}
}
@media screen and (max-width:500px) {
  /* .internalBox{
   padding-top: 1rem;
}
.phone{
  display: none;
 }
 .phone2{
  display: none;
 }
 .phone3{
  display: none;
 }
 .circlesText {
  font-size: 1rem;
  margin-top: 1rem;
  text-align: center;
  margin-left: 1px;
}
.circles1 {
    max-width: 20rem;
    max-height: 13rem;
    border-radius: 10px;
   margin-right: 0px;
}
.circles2 {
  max-width: 20rem;
  max-height: 13rem;
  border-radius: 10px;
 margin-left: 3px;
}
.svg{
    margin-top: .3rem;
  width:20px;
 }
 .vertical-timeline-element-content p{
  line-height: 1 !important;
 }
 .btn{
  padding: 1rem;
  margin-top: 10rem;
} */
 .box1{
  display: none;
 
 }
 .box2{
  display: flex;
 align-items: center;
  flex-direction: column;
 }
 .card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: .5rem;
  border-radius: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 3rem;

 }
 .card h3{
  text-align: center;
  font-size: 1.5rem;
  font-family: 'GalanoGrotesque-semibold';
 }
 .card img{
  width: 20rem;
 }
 .card p {
  text-align: center;

  font-size: 1.125rem;
  font-family: 'GalanoGrotesque-Medium';
 }
 .btn{
  margin-top: 0rem;
  margin-bottom: 3rem;
}
}

