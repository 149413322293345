.container {
  max-width: 1440px;
  margin: auto;
}

.banner {
  width: 66%;
  text-align: center;
  background: #333333;
  color: #ffffff;
  margin: 5.6% auto;
  padding: 5%;
  box-sizing: border-box;
  border-radius: 30px / 30px;
}

.locationAddress {
  margin-top: 0.5rem;
}

.bannerTitle {
  font-size: 2rem;
  font-weight: 800;
  line-height: 3.5rem;
  font-family: GalanoGrotesqueAlt-Bold;
}

.form_pic_con {
  width: 80%;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 2rem 7.5rem; */
  padding: 0 7% 0 8%;
  box-sizing: border-box;
  text-align: center;
  margin-top: 5.6%;
  margin: 5.6% auto;
}

.form_pic {
  height: 100%;
  width: 100%;
  background: url("../../../public/Images/formpic.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  padding: 3rem 0;
  text-align: center;
}

.form_pic p {
  font-size: 1rem;
  font-weight: 900;
  font-family: GalanoGrotesqueAlt-Bold;
}

#fpt2 {
  padding-top: 1rem;
}

#fpt3 {
  margin-top: 2%;
  font-size: 1rem;
  font-weight: 400;
  font-family: GalanoGrotesque-Medium;
}

.form_pic section {
  padding-bottom: 3%;
  color: #d8d8d8;
}

.main_form {
  display: flex;
  justify-content: center;
  margin-top: 4%;
}

.sub_form {
  width: 82%;
  height: auto;
}

.addressContainer {
  display: flex;
  justify-content: space-between;
  /* margin-top: 0.5rem; */
  height: 103px;
  margin-bottom: 16px;
}

.addressParent {
  width: 100%;
  margin-right: 10px;
  margin-bottom: 1rem;
}

.location,
.legal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  justify-content: space-between;
  margin-top: 2rem;
}

.location_b,
.legal_b {
  margin-right: 10%;
}

.loc,
.legalRepresentative,
.im {
  margin-top: 1rem;
  font-size: 1.9rem;
  font-family: GalanoGrotesque-Bold, sans-serif;
  color: #333333;
  /* border-bottom: 1px solid #333333; */
  padding-bottom: 0.2rem;
  display: inline;
  /* background-color: red; */
}

.paging {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 7rem;
}

.count {
  background-color: #f7f1f1;
  font-family: 'MontserratBold';
  width: 5rem;
  height: 3rem;
  border-radius: .5rem;
  font-size: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

}

.formMargin {
  margin-top: 1rem;
}

.upload {
  margin-top: 1rem;
  /* height: 220px; */
}

.job_pos {
  width: 48%;
  margin: 0.5rem auto;
}

.label {
  font-size: 1rem;
  font-family: GalanoGrotesque-Medium;
  color: #333333;
  margin-left: 5px;
  margin-top: 2rem;
}

.form_field_name {
  margin-top: 0.5rem;
  height: 55px;
  width: 100%;
  padding-left: 8px;
  border-radius: 8px;
  background: #f7fcff;
  border: 2px solid transparent;
  margin-bottom: 2rem;
  font-family: GalanoGrotesque-Medium;
  text-indent: 14px;
}

.form_field {
  margin-top: 0.5rem;
  height: 55px;
  width: 100%;
  padding-left: 8px;
  border-radius: 8px;
  background: #f7fcff;
  border: 2px solid transparent;
  margin-bottom: 1rem;
  font-family: GalanoGrotesque-Medium;
  text-indent: 14px;
}

.form_field::placeholder {
  font-size: 1rem;
  padding-right: 14px;
}

Select::placeholder {
  text-indent: 14px;
}

.selectOption {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../../public/Images/dropdown.svg");
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 50%;
  background-size: 15px;
  border-radius: 8px;
  border: 2px solid transparent;
  font-family: GalanoGrotesque-Medium;
}

.selectOption option {
  background-color: #e5e5e5;
  font-size: 1rem !important;
  height: 40px;
  margin-top: 50px;
  border-bottom: 1px solid red !important;
}

.select-wrapper {
  margin-top: 50px;
}

.form_field1 {
  margin-left: 13rem;
  height: 2rem;
  width: 40%;
  border-radius: 8px;
  background: #f2f2f2;
  border: 2px solid transparent;
  font-family: GalanoGrotesque-Medium;
}

.form_field1:hover {
  border: 2px solid rgb(66, 135, 245);
}

.upload_pic_con {
  display: flex;
  justify-content: center;
}

.upload_pic {
  width: 80%;
  height: 50%;
  border: 2px solid gray;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  position: relative;
  margin-right: 2%;
}

.form_btn button {
  padding: 0.8rem 1rem;
  display: flex;
  justify-content: center;
  background: #333333;
  color: #ffffff;
  border: none;
  font-family: GalanoGrotesque-Regular;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
}

.underLineBorder img {
  width: 12%;
}

.underLineBorderTwo img {
  width: 30%;
  object-fit: contain;
}

.underLineBorderThree img {
  width: 10.5%;
}


.error {
  color: red;
  margin-top: 0.5rem;
  margin-left: 1rem;
}

@media screen and (max-width: 767px) {
  .container {
    width: 95%;
    margin: auto;
  }


  .banner {
    width: 95%;
    border-radius: 10px 10px;
  }

  .bannerSubTitle {
    font-size: 0.65rem;
  }

  .bannerTitle {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  .form_pic_con {
    width: 100%;
  }

  .loc,
  .legalRepresentative,
  .im {
    font-size: 4vw;
  }

  .underLineBorder img {
    width: 20%;
  }

  .underLineBorderTwo img {
    width: 67%;
    object-fit: contain;
  }

  .underLineBorderThree img {
    width: 23%;
  }

  .addressContainer {
    display: inline;
  }

  .location,
  .legal {
    grid-template-columns: 1fr;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }

  .location_b,
  .legal_b {
    margin-right: 0;
  }

  .label {
    font-size: 16px;
    margin-left: 5px;
    margin-top: 40px !important;
  }

  .form_field {
    height: 50px;
    font-size: 16px;
    margin-left: 0px;
  }

  .form_field::placeholder {
    font-size: 16px;
  }

  .job_pos {
    width: 100%;
  }

  .form_btn {
    margin-top: 2% !important;
  }

  .form_btn button {
    padding: 0.5rem 1rem;
    font-size: 0.7rem;
    font-family: 'GalanoGrotesqueAltHeavy-Medium';
    border-radius: 5px;
    font-weight: 500;
  }

  .upload {
    margin-top: 2rem;
    /* height: 100px; */
  }

  .form_btn {
    margin-top: 2rem;
  }


}

@media screen and (min-width: 768px) and (max-width: 1023px) {

  /* .form_pic_con {
    padding: 0 3.5rem;
    margin-left: 2%;
  } */
  .form_pic {
    padding: 3rem 0;
  }

  .form_pic p {
    font-size: 2rem;
    font-weight: 900;
  }

  #fpt3 {
    font-size: 1rem;
  }

  .location_b,
  .legal_b {
    margin-right: 10%;
  }

  .form_btn {
    margin-top: 3rem;
  }

  .form_btn button {
    width: 21%;
    padding: 0.9rem 1.5rem;
    font-size: 1rem;
  }
}

.continue {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin-top: 10px;
}

.continue button {
  padding: 0.8rem 1rem;
  display: flex;
  justify-content: center;
  background: #333333;
  color: #ffffff;
  border: none;
  font-family: GalanoGrotesque-Regular;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  .continue button {
    padding: 0.5rem 1rem;
    font-size: 0.6rem;
    font-family: GalanoGrotesqueAltHeavy-Medium;
    border-radius: 5px;
    font-weight: 500;
  }


  .paging {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 0rem;
  }

  .count {

    background-color: #f7f1f1;
    font-family: 'MontserratMedium';
    width: 4rem;
    height: 2.5rem;
    border-radius: .5rem;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

  }



}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .continue {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {

  /* .form_pic_con {
    padding: 0 6.5rem 0 7rem;
  } */
  .form_pic {
    border-radius: 30px;
    padding: 5rem 0;
  }

  .form_pic p {
    font-size: 2.5rem;
  }

  #fpt3 {
    font-size: 1rem;
  }

  .location_b,
  .legal_b {
    margin-right: 10%;
  }

  .form_btn {
    margin-top: 6.5rem;
  }

  .form_btn button {
    /* padding: 1rem 2.8rem; */
    font-size: 1.5rem;
  }
}