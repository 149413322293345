.container {
  height: 400%;
  display: flex;
  flex-direction: column;
  max-width: 1460px !important;
  margin: auto;
}

.banner {
  height: 30%;
  display: flex;
  background-image: url("/public/Images/rectangle.png");
  background-size: cover;
  background-clip: border-box;
  background-position: 0% 100%;
  margin: auto;
  width: 100%;
  max-width: 1460px !important;

}

.scrollElement {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.scrollElement.fadeIn {
  opacity: 1;
  transform: translateY(0);
}

.bannerLeft {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bannerRight {
  width: 50%;
  display: flex;
  gap: 2rem;
  margin-top: 5rem;
  justify-content: center;
  align-items: center;
  /* transform-origin: top left; Rotation starts from the top-left corner */
}

.bannerRight img {
  border: 6px solid black;
  border-radius: 20px;
  margin-right: 3rem;

  width: 30%;
  /* margin-top: 7.9%; */
  margin-left: 5.5%;
  /* transform-origin: top left; */
  /* Set the origin to top-left corner */
  /* transition: transform 0.5s ease-in-out; */
  /* Smooth transition */
}

/* .bannerRight img:hover {
  transform: translate(-50px,-25px);

} */

.bannerImageRight {
  margin-left: -3.5% !important;
}

.bannerHeading {
  width: 100%;
  padding-top: 15%;
  padding-left: 16.5%;
}

.bannerHeading p {
  font-family: 'GalanoGrotesque-SemiBold';
  font-style: normal;
  font-size: 2.4rem;
  color: #333333;
  line-height: 2.4rem;
  text-transform: uppercase;
}

.description {
  /* width: 100%; */
  margin-left: 17%;
  margin-top: 3%;
  margin-bottom: 1%;
}

.description p {
  color: #acacac;
  font-family: 'GalanoGrotesque-Medium';
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.bannerButton {
  /* width: 80%; */
  margin-left: 16.9%;
  text-transform: uppercase;
  display: flex;
  gap: 3%;
}

.bannerButton button {
  padding: 8px 15px;
  background-color: #333333;
  color: #ffffff;
  border-radius: 8px;
  margin-top: 3.5%;
  font-family: 'GalanoGrotesque-SemiBold';
  font-size: 1.1rem;
  text-transform: uppercase;
  cursor: pointer;
}

.bannerButton button:hover {
  background-color: #1b1b1b;
  color: #e4e2e2;
  transition: .3s;
}

button:hover {
  cursor: pointer;
}

.bannerButtonAds {
  background-color: #f2f2f2 !important;
  color: #333333 !important;
  border: 1px solid #333333;
  border-radius: 5px;
}

.benifits {

  width: 80%;
  margin: auto;
  margin-top: 5%;
  padding: 20px 10px;
  display: none;

}

.fullWidth {
  width: 100%;
  height: auto;
  margin-top: 5%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.benifits1 {
  width: 100%;

  background-color: #f6f6f6;
  padding: 60px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1460px !important;

}

.benifits img {
  width: 2.5rem;
  margin-bottom: 10px;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  margin: auto;

}

.card1 {
  width: 80%;
  margin: auto;
}

.card1ContentHeder p {
  line-height: 16px;
  font-weight: 700;
}

.benifitsHeading {
  /* height: 12%; */
  width: 100%;
  margin: auto;
  text-align: center;
}

.benifitsHeading p {
  font-size: 32px;
  font-family: 'GalanoGrotesque-SemiBold';
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
}

.cardRowMargin {
  margin-top: 1%;
}

.cardLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

.cardRow {
  width: 70%;
  height: 45%;
  display: flex;
  justify-content: center;
  gap: 2%;
  margin: auto;

}

.card {
  width: 35%;
  /* border-radius: 25px; */
  /* background: red; */
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 4%; */
  /* padding-bottom: 4%; */
}

.cardImage {
  /* width: 30%; */
}

.cardContentHeder {
  width: 100%;
  display: flex;
}

.cardContentHeder p {
  display: inline-block;
  width: 100%;
  margin-top: 4%;
  font-size: 16px;
  line-height: 1.2rem;
  font-family: 'GalanoGrotesque-SemiBold';
  text-align: center;
}

.cardContentDetails {
  margin-top: 5%;
  width: 100%;
  text-align: center;
}

.cardContentDetails p {
  font-family: 'GalanoGrotesque-Medium';
  font-size: 1rem;
  line-height: 1.5rem;
  color: #333;
  opacity: 0.8;
}

/* .card {
  width: 24%;
  border-radius: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
} */
.boxContainer {
  width: 75%;
  height: 75%;
  margin: auto;
  margin-top: 5%;
  display: flex;
  max-width: 1460px !important;

}

.boxContainer1 {
  display: none;
}

.boxLeft {
  width: 50%;
  background-color: #333333;
  border-radius: 20px 0 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 4%; */
}

.boxRight {
  width: 50%;
  background-color: #333333;
  border-radius: 0 40px 40px 0;
  height: 100%;
}

.boxRight img {
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
  top: -0.5px;
  position: relative;
}

.boxHeder {
  /* margin-top: 13%; */
}

.boxHeder p {
  font-size: 1.7rem;
  line-height: 2rem;
  font-family: 'GalanoGrotesque-SemiBold';
  color: #ffffff;
  text-transform: uppercase;
  margin-left: 8%;

}

.netmeUnderline {
  text-decoration: underline;
  text-decoration-color: #ffffff;
  text-decoration-thickness: 1px;
  text-underline-offset: 1px;
}

.boxContent {
  display: flex;
}

.boxContent p {
  display: inline-block;
  font-family: 'GalanoGrotesque-Medium';
  font-size: 1rem;
  line-height: 1rem;
  opacity: 0.8;
  color: #333;
  margin-left: 8%;
  width: 70%;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 14px;
}

.boxContent img {
  width: auto;
  height: 2rem;
  /* aspect-ratio: 1; */
  margin-left: .5rem;
  margin-top: 2.5%;
}

.boxLeft button {
  width: 16rem;

  padding: 8px 15px;
  font-size: 1.1rem;
  background-color: #ffffff;
  color: #333333;
  margin-left: 8%;
  margin-top: 5%;
  border-radius: 8px;
  font-family: 'GalanoGrotesque-SemiBold';
}

button:hover {
  cursor: pointer;
}

.adsContainer {
  width: 100%;
  padding-top: 8%;
  display: flex;
  justify-content: center;
  max-width: 1460px !important;
  margin: auto;

}

.mobileAdsBanner {
  margin-left: 8rem;
  /* background-color: red; */
  width: 30%;
  background-image: url("../../../public/Images/bgAnim.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 2%;
  background-position: center;
}

.image {
  display: none;
}

.adsContainer img {
  width: 70%;
  height: auto;
  margin-left: 25%;
  /* -webkit-animation-name: fadeOut;
  -moz-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite; */
  transition: ease-in-out;
  transition-delay: 2s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.95;
  }
}

.mobileAdsContent {
  width: 60%;
}

.mobileAdsHeader {
  max-width: 57%;
  font-size: 1.2rem;
  font-family: 'GalanoGrotesque-SemiBold';
  margin-top: 10%;
  margin-left: 5%;
}

.mobileAdsHeader p {
  font-size: 2rem;
}

.mobileAdsDetails {
  width: 54%;
  font-size: 1rem;
  /* font-family: GalanoGrotesque-Regular; */
  margin-top: 1%;
  margin-left: 5%;
  color: #acacac;
  /* line-height: 1.2rem; */
}

.mobileAdsDetails p {
  font-family: 'GalanoGrotesque-Medium';
  /* line-height: 1.2rem; */
}

.mobileAdsContent button {
  padding: 8px 15px;
  background-color: #333333;
  color: #ffffff;
  border-radius: 8px;
  margin-top: 3.5%;
  font-family: 'GalanoGrotesque-SemiBold';
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 5%;
}

.visibleonmobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .cardRow {
    width: 90%;
  }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {

  .boxHeder {
    /* background-color: red; */
    font-size: 2rem;
  }

  .boxHeder p {
    font-size: 1.5rem;
    margin-left: 8%;
  }

  .boxContent p {
    font-size: 0.7rem;
  }

  .boxContent img {
    /* background-color: red; */
    /* width: 20px; */
    margin-top: 1rem;
    margin-right: 1rem;
    /* margin-left: 4%; */
  }

  .boxLeft button {
    font-size: .8rem;
  }

  /* .boxContainer {
    background-color: red;
    height: 40%;
  } */

  /* .bannerHeading p {
    font-size: 1.3vw;
    line-height: 1.8vw;
  } */

  /* .cardRow {
    height: 27%;
    gap: 2%;
  }

  .cardImage {
    width: 15%;
    margin-top: 1%;
  }

  .boxHeder {
    margin-top: 12%;
  }
  .boxHeder p {
    font-size: 1.3vw;
    margin-left: 8%;
  }
  .netmeUnderline {
    text-underline-offset: 8px;
  }

  .boxRight img {
    height: 100%;
  }
  .boxContent p {
    font-size: 0.7vw;
  }
  .boxContent img {
    width: 20px;
    margin-left: 4%;
  }

  .boxLeft button {
    width: 55%;
    height: 16%;
    font-size: 0.6vw;
    border-radius: 5px !important;
  }

  .mobileAdsBanner img {
    width: 65%;
  }

  .mobileAdsContent {
    width: 70%;
  }

  .mobileAdsHeader {
    font-size: 1.18vw;
  }

  .mobileAdsDetails {
    font-size: 0.95vw;
  }

  .mobileAdsContent button {
    height: 45px;
    font-size: 0.85vw;
  } */
}


@media screen and (max-width:767px) {
  .container {
    margin-top: -0.5%;
  }

  .banner {
    flex-direction: column;

  }

  .visibleonmobile {
    display: none;
  }

  .bannerLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bannerRight {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    gap: 1.5rem;
  }

  .bannerRight img {
    width: 40%;
    margin-top: 7%;
    margin-left: 0;
    margin-right: 0rem;

  }

  .bannerImageRight {
    margin-left: 0;
  }

  .bannerHeading {
    width: 100%;
    padding-top: 5%;
    padding-left: 0;
    text-align: center;
  }

  .bannerHeading p {
    font-size: 1.2rem;
    /* line-height: vw; */
  }

  .visibleonmobile {
    display: block;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #acacac;
    font-family: 'GalanoGrotesque-Medium';
    font-size: 1rem;
    line-height: 1.5rem;

  }

  .description {
    display: none;
    /* width: 100%;
    margin-left: 0;
    margin-top: 0%;
    margin-bottom: 0%;
    text-align: center;
    padding-left: .5rem;
    padding-right: .5rem; */
  }

  .description p {
    font-size: 1rem;
  }

  .bannerButton {
    width: 100%;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bannerButton button {
    width: 80%;
    padding: 2.5vw 1vw;
    margin-top: 3.5%;
    font-size: 1rem;
  }

  .benifits {
    width: 100%;
    padding: 0px 0px;
    display: block;
  }

  .benifits1 {
    /* display: none; */
  }


  .cardContainer {
    gap: 30px;
    margin-top: 20px;
    /* padding: 20px */
  }

  .card1 {
    width: 100%;
    margin: auto;
  }

  .card1ContentHeder p {
    line-height: 1.2rem;
    font-weight: 700;
  }

  .benifitsHeading p {
    font-size: 5vw;
    margin-top: 12%;
  }

  .cardRowMargin {
    margin-top: 4%;
  }

  .cardRow {
    width: 100%;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2%;
    background: #f6f6f6;

  }


  .card {
    width: 85%;
    border-radius: 15px;
    /* background: #f6f6f6; */
    /* background-color:red; */
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .cardImage {
    /* background-color: red; */
    /* width: 10%; */
    width: 22%;
    margin-top: 1%;
  }

  .cardContent {
    width: 90%;
  }

  .cardContentHeder {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .cardContentHeder p {
    width: 100%;
    margin-top: 2%;
    font-size: 1rem;
    /* line-height: 1rem; */
    text-align: center;
  }

  .cardContentDetails {
    margin-top: 2%;
    width: 100%;
    text-align: center;
  }

  .cardContentDetails p {
    font-size: 0.9rem;
    /* line-height: 0.9rem; */
  }

  .adsContainer img {
    margin-left: 0%;
  }

  .boxContainer {
    /* width: 80%;
    margin: auto;
    margin-top: 8%;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 80px 80px 80px 80px; */
    display: none;
  }

  .boxContainer1 {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .boxContainer1 h1 {
    padding-top: .8rem;
    text-align: center;
    font-size: 1.7rem;
    font-family: 'GalanoGrotesque-SemiBold';
    margin-bottom: .5rem;
  }

  .boxContainer1 p {
    margin-top: .5rem;
    text-align: center;
    font-size: 1.125rem;
    line-height: 2rem;
    font-family: 'GalanoGrotesque-Medium';
  }

  .boxContainer1 button {
    margin-top: 1rem;
    background-color: #333333;
    padding: .8rem;
    border-radius: 15px;
    color: white;
    font-family: 'GalanoGrotesque-Medium';
  }

  .boxLeft {
    width: 100%;
    padding: 1% 0%;
    background-color: #333333;
    border-radius: 32px 32px 0px 0px;
  }

  .boxRight {
    width: 100%;
    border-radius: 0px;
    border-radius: 0px 0px 35px 35px;
  }

  .boxRight img {
    top: 2px;
    position: relative;



  }

  .boxHeder p {
    font-size: 4vw;
    margin-left: 0px;
    margin-top: 3%;
  }


  .netmeUnderline {
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
  }

  .boxContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .boxContent p {
    font-size: 0.6rem;
    /* line-height: 2vw; */
    margin-left: 0px;
    color: #ffffff;
    /* margin-top: 4rem; */
  }

  .boxContent img {
    width: 3rem;
    height: auto;
    margin-left: 0px;
  }

  .boxLeft button {
    /* background-color: red; */
    width: 50%;
    padding: 0.5vw 5vw;
    font-size: 2vw;
    height: 25%;
    margin-left: 26%;
    margin-top: 3%;
    border-radius: 5px;
  }

  button:hover {
    cursor: pointer;
  }

  .adsContainer {
    width: 100%;
    margin-top: 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0%;
  }

  .mobileAdsBanner {
    display: none;
    /* background-color: red; */
    width: 60%;
    background-image: url("../../../public/Images/bgAnim.gif");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 2%;
    background-position: center;
  }

  .image {
    display: block;
    margin-left: 0%;
    height: 50%;
    width: auto;
  }

  .mobileAdsBanner img {
    display: none;

  }

  .mobileAdsContent {
    /* background-color: red; */
    /* width: 100%; */
    width: 118%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .mobileAdsDetails {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 80%;
    margin-left: 0px;
  }

  .mobileAdsDetails p {
    /* background-color: red; */
    font-size: 0.8rem;
    margin-left: 0rem;
    line-height: 1.5rem;
  }

  .mobileAdsHeader {

    width: 100%;
    font-size: 2.5vw;
    margin-top: 3%;
    margin-left: 0px;
  }

  .mobileAdsHeader p {
    font-size: 1rem;
    text-align: center;
  }

  .mobileAdsDetails {
    font-size: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    /* margin: 0px; */
  }

  .mobileAdsContent button {
    height: 2rem;
    padding: 0.2rem 0.8rem;
    /* width: 30%; */
    border-radius: 5px;
    margin-left: 0px;
    font-size: 0.7rem;
  }

}

/* 
@media screen and (max-width: 499px) {


  .mobileAdsDetails p {
    font-size: 0.8rem;
    margin-left: 2rem;
    line-height: 1.5rem;
  }

} */