/* General styling */
.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.title {
    font-family: 'GalanoGrotesque-SemiBold';
    font-size: 2.188rem;
    color: #333333;
    text-align: center;
    margin-bottom: 1.5rem;
}

.card {
    background-color: rgb(140, 201, 233);
    border-radius: 1.25rem;
    width: 100%;
    /* Make the card take full width of the container */
    max-width: 50rem;
    /* Set a max width for large screens */
    padding: 1.25rem;
    margin-top: 1.875rem;
    box-sizing: border-box;
}

.cardTitle {
    font-family: 'GalanoGrotesque-SemiBold';
    font-size: 1.5rem;
    color: white;
    margin-bottom: 0.5rem;
}

.cardDetail {
    font-family: 'GalanoGrotesque-Medium';
    font-size: 1.125rem;
    color: white;
}

/* Small screens (mobile) */
@media (max-width: 600px) {
    .title {
        font-size: 1.8rem;
    }

    .card {
        width: 100%;
        padding: 1rem;
        margin-top: 1rem;
    }

    .cardTitle {
        text-align: center;
        font-size: 1.3rem;
    }

    .cardDetail {
        text-align: center;
        padding-top: 0.5rem;
    }
}