.container {
	max-width: 1440px;
	margin: auto;
}

.voucher_prices {
	font-size: 28px;
	font-weight: bolder;
}

.voucher_text {
	font-weight: bolder;
}

.form_pic_con {
	width: 80%;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	/* padding: 2rem 7.5rem; */
	padding: 0 7% 0 8%;
	box-sizing: border-box;
	margin-top: 2%;
}
.paging_container{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 55%;
  }

.form2_pic {
	height: 100%;
	width: 100%;
	background: url('../../../public/Images/form2pic.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
	color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 30px;
	text-align: center;
	padding: 6rem 0;
}

.form2_pic p {
	font-size: 2.8rem;
	font-family: GalanoGrotesqueAltHeavy, sans-serif;
}

.form2_main {
	box-sizing: border-box;
}

.choose_pkg {
	display: flex;
	justify-content: center;
	margin-top: 6%;
}

.one_x {
	font-size: 0.85rem;
	color: #333333;
	line-height: 0.1rem;
	margin: 0.5rem 0;
}

.choose_pkg p {
	font-size: 1.5rem;
	font-family: GalanoGrotesque-Medium;
	color: #929292;
}

.main_con {
	width: 100%;
	display: flex;
	justify-content: space-between;
	background-color: #f7fcff;
	margin-top: 3.8%;
	padding: 3rem 3.4rem;
}

.list {
	margin-top: 5rem;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	color: #929292;
	font-family: GalanoGrotesque-Medium;
}

.list li {
	font-size: 1.2rem;
	font-family: GalanoGrotesque-Medium;
	margin-bottom: 0;
}

.listItem {
	display: flex;
	gap: 4%;
	align-items: center;
}

.tooltip {
	width: 4% !important;
}

.tooltip img {
	margin-top: 0 !important;
}

.txt_pic {
	height: 100%;
	width: 35%;
}

.txt_pic>img {
	width: 14%;
	margin-top: 1rem;
}

.dash {
	width: 10%;
	margin: 0.5rem 0;
}

.maincards_div {
	width: 65%;
	padding: 1.5rem;
	border-radius: 49px;
	background: #ffffff;
}

.cards_div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
}

.card1,
.card2,
.card3 {
	width: 32%;
	background: #f1f1f1;
	border-radius: 35px;
	padding: 0.8rem;
}

.innerCard {
	display: flex;
	gap: 1.5rem;
	height: 90%;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
}

.innerCard p {
	font-size: 1.5rem;
	color: #333333;
	font-family: GalanoGrotesque-Medium, sans-serif;
}

.card3,
.card2,
.card1 {
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

#ofnn,
#tznn,
#fnnn {
	width: 60%;
	margin-top: 3%;
}

.tick {
	width: 15%;
}

.bttn,
.bttn2,
.bttn3 {
	padding: 0.6rem;
	border-radius: 8px;
	border: none;
	background: #8dc3df;
	margin-left: 1.3rem;
	margin-right: 1.3rem;
	color: #ffffff;
	font-size: 1.2rem;
	margin-top: 2.5rem;
	margin-bottom: 1rem;
	font-family: GalanoGrotesque-Bold;
}

.bttn:hover {
	background: #8dc3df;
}

.bttn2:hover {
	background: #518099;
}

.bttn3:hover {
	background: #333333;
}

/* .bttn {
  margin-top: 1.4rem;
} */
.bttn2 {
	background: #518099;
}

.bttn3 {
	background: #333333;
}

.reg_process {
	display: flex;
	justify-content: center;
	margin-top: 2%;
}

.reg_process p {
	font-size: 1.4rem;
	color: #333333;
	letter-spacing: 0.06rem;
	font-family: GalanoGrotesque-Medium;
	font-weight: 600;
}

.round {
	width: 8%;
}

.payment_lane {
	display: flex;
	justify-content: center;
}

.klarna {
	margin: 0px 5px;
}

.klarna,
.amazon_pay,
.paypal {
	padding: 0.7rem;
	width: 50%;
	border: 1px solid #bdbdbd;
	display: flex;
	align-items: center;
	border-radius: 5px;
	margin-top: 10px;
}

.klarna label,
.amazon_pay label,
.paypal label {
	font-size: 1rem;
	font-family: GalanoGrotesque-Medium, sans-serif;
	color: #333333;
}

.asidLeft {
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
}

.card_box {
	background: #fff;
	padding: 0px 10px;
	max-width: 300px;
	width: 100%;
}

.contentBox {
	padding: 10px 0px;
	display: flex;
	flex-direction: column;
	/* align-items: end; */
}

.contentBox p {
	font-size: 18px;
	text-align: left;
}

.cardFooter {
	padding: 10px 0px;
	border-top: 1px solid #ccc;
}

.icon_pic {
	/* width: 30%; */
	width: 170px;
	height: 80.8px;
}

.voucher {
	display: flex;
}

.voucher p {
	color: #333333;
	font-size: 1rem;
	font-family: GalanoGrotesque-Medium;
	font-weight: 600;
}

.enter_voucher {
	display: flex;
	align-items: center;
	gap: 10px;
}

.enter_voucher input {
	background: #f7fcff;
	display: flex;
	padding: 1rem 2rem;
	color: #bbbbbb;
	border-radius: 8px;
	width: 100%;
}

input[type='text'] {
	color: #333333;
}

.remove_voucher {
	color: red;
	font-size: xx-large;
	cursor: pointer;
}

.remove_voucher:hover {
	background: #fff;
}

.continue {
	display: flex;
	justify-content: space-between !important;
	align-items: center;
	margin-top: 10px;
}

.continue button {
	padding: 0.8rem 1rem;
	display: flex;
	justify-content: center;
	background: #333333;
	color: #ffffff;
	border: none;
	font-family: GalanoGrotesque-Regular;
	font-size: 1rem;
	font-weight: 500;
	border-radius: 5px;
}

[type='radio']:checked,
[type='radio']:not(:checked) {
	position: absolute;
	left: -9999px;
}

[type='radio']:checked+label,
[type='radio']:not(:checked)+label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: #666;
}

[type='radio']:checked+label:before,
[type='radio']:not(:checked)+label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	border: 1px solid black;
	border-radius: 100%;
	background: #fff;
}

[type='radio']:checked+label:after,
[type='radio']:not(:checked)+label:after {
	content: '';
	width: 12px;
	height: 12px;
	background: black;
	position: absolute;
	top: 4px;
	left: 4px;
	border-radius: 100%;
	transition: all 0.2s ease;
}

[type='radio']:not(:checked)+label:after {
	opacity: 0;
	transform: scale(0);
}

[type='radio']:checked+label:after {
	opacity: 1;
	transform: scale(1);
}

.ant-tooltip {
	background-color: #f0f0f0 !important;
	/* Change tooltip background color */
	color: #333;
	/* Change tooltip text color */
	border: 1px solid #ccc;
	/* Change tooltip border */
}

/* Customize the tooltip arrow */
.ant-tooltip-arrow-content {
	background-color: #f0f0f0;
	/* Change arrow background color */
}

/* Customize the tooltip content */
.ant-tooltip-inner {
	padding: 8px;
	/* Adjust tooltip padding */
	font-size: 14px;
	/* Change tooltip font size */
}

.ant-tooltip-content {
	width: 300px !important;
}


.parentContainer {
	display: flex;
	justify-content: flex-end;
	margin-right: 10rem;
	/* Aligns children to the right */
}

.count {
	background-color: #f7f1f1;
	font-family: 'MontserratBold';
	width: 5rem;
	height: 3rem;
	border-radius: .5rem;
	font-size: 1rem;

	display: flex;
	justify-content: center;
	align-items: center;
}



@media screen and (max-width: 767px) {
	.card_box {
		max-width: 100%;
	}

	.paging_container{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 80%;
	  }

	.grid {
		/* flex-direction: column;
		gap: 10px;
		align-items: center; */
	}

	.listItem {
		justify-content: space-between;
	}

	.choose_pkg p {
		font-size: 5vw;
	}

	.one_x {
		font-size: 2vw;
		color: #333333;
		line-height: 0.1rem;
		margin: 0.5rem 0;
	}

	.reg_process p {
		font-size: 4vw;
	}

	.form2_main {
		padding: 3vw;
		text-align: center;
		word-wrap: break-word;
	}

	.main_con {
		width: 100%;
		display: flex;
		justify-items: center;
		align-items: center;
		justify-content: space-between;
		margin-top: 2%;
		padding: 5vw;
		text-align: left;
		flex-direction: column;
	}

	.txt_pic {
		width: 100%;
	}

	.txt_pic img {
		width: 15%;
		margin: 0px;
	}

	.list {
		width: 100%;
		margin-top: 2vw;
		gap: 1vw;
		padding: 1vw;
	}

	.list li {
		font-size: 0.8rem;
	}

	.maincards_div {
		width: 100%;
		padding: 3vw;
		border-radius: 12px;
		background: #ffffff;
	}

	.cards_div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 2vw;
		height: 100%;
		/* flex-direction: column; */
	}

	.card1,
	.card2,
	.card3 {
		width: 40%;
		background: #f1f1f1;
		border-radius: 12px;
		padding: 0;
	}

	.innerCard {
		display: flex;
		gap: 2vw;
		height: 90%;
		flex-direction: column;
		align-items: center;
	}

	.innerCard p {
		font-size: 2vw;
		color: #333333;
		font-family: GalanoGrotesque-Medium, sans-serif;
		margin-top: 2vw;
	}

	.bttn,
	.bttn2,
	.bttn3 {
		width: 75%;
		padding: 0.5vw;
		border-radius: 8px;
		border: none;
		background: #8dc3df;
		margin-left: 2vw;
		margin-right: 0;
		font-size: 1vw;
		margin-top: 0;
		margin-bottom: 2vw;
	}

	.payment_lane {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 5vw;
	}

	.klarna,
	.amazon_pay,
	.paypal {
		height: 15vw;
		padding: 0.7rem;
		width: 70%;
	}

	.klarna label,
	.amazon_pay label,
	.paypal label {
		font-size: 4vw;
		font-family: GalanoGrotesque-Medium, sans-serif;
		color: #333333;
	}

	.continue button {
		padding: 0.5rem 1rem;
		font-size: 0.6rem;
		font-family: GalanoGrotesqueAltHeavy-Medium;
		border-radius: 5px;
		font-weight: 500;
	}

	.tooltip {
		width: 8% !important;
	}

	.accept {
		text-align: left;
	}

	.parentContainer {
		display: flex;
		justify-content: center;
		margin-right: 12rem;
		/* Aligns children to the right */
	}

	.count {
		background-color: #f7f1f1;
		font-family: 'MontserratBold';
		width: 4rem;
		height: 3rem;
		border-radius: .5rem;
		font-size: 1rem;

		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.form_pic_con {
		padding: 0 5.5rem;
	}

	.form2_pic {
		border-radius: 30px;
		padding: 4rem 0;
	}

	/* #fpt2 {
    font-size: 1rem;
    margin-top: 2%;
    font-weight: 900;
    font-family: GalanoGrotesqueAlt-Black;
  } */

	.form2_pic p {
		font-size: 2rem;
		font-weight: 800;
		font-family: GalanoGrotesqueAlt-Black;
	}

	.grid {
		padding: 0px 20px;
	}

	.form2_main {
		box-sizing: border-box;
	}

	.choose_pkg {
		display: flex;
		justify-content: center;
		text-align: center;
		margin-top: 2%;
	}

	/* .choose_pkg p {
    font-size: 1.5rem;
    font-family: GalanoGrotesque-Medium;
    color: #929292;
  } */
	/* .main_con {
    height: 60vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #f7fcff;
    margin-top: 6%;
    padding: 2rem;
  } */

	/* .list {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #929292;
    font-size: 0.8rem;
    font-family: GalanoGrotesque-Medium;
  } */

	#ofnn,
	#tznn,
	#fnnn {
		width: 60%;
	}

	.tick {
		width: 15%;
	}

	.bttn,
	.bttn2,
	.bttn3 {
		padding: 0.5rem 1.5rem;
		border-radius: 8px;
		margin-left: 0;
		margin-right: 0;
		border: none;
		background: #8dc3df;
		color: #ffffff;
		font-size: 0.75rem;
		font-family: GalanoGrotesque-Bold;
	}

	/* .bttn {
    margin-top: 1.4rem;
  } */
	.bttn2 {
		background: #518099;
	}

	.bttn3 {
		background: #333333;
	}

	.reg_process {
		display: flex;
		justify-content: center;
		margin-top: 2%;
	}

	.round {
		width: 8%;
	}

	.klarna label,
	.amazon_pay label,
	.paypal label {
		font-size: 0.85rem;
		color: #333333;
	}

	.voucher {
		display: flex;
	}

	.voucher p {
		color: #333333;
		font-size: 1.2rem;
		font-weight: 600;
	}

	.enter_voucher {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}

	.enter_voucher p {
		background: #f7fcff;
		display: flex;
		justify-content: center;
		padding: 2% 15vw;
		color: #bbbbbb;
		border-radius: 8px;
	}

	.continue {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.list li {
		font-size: 0.8rem;
	}
}

.grid {
	display: flex;
	justify-content: center;
	max-width: 950px;
	gap: 30px;
	margin:  auto;
}

.heading {
	font-family: 'MontserratBold';
	font-size: 1.2rem;
}


@media screen and (min-width: 1024px) and (max-width: 1439px) {
	.form_pic_con {
		padding: 0 7.5rem;
	}

	.form2_pic {
		border-radius: 30px;
		padding: 2rem 0;
	}

	/* #fpt2 {
    font-size: 1.5rem;
    margin-top: 2%;
    font-weight: 900;
    font-family: "Darker Grotesque", sans-serif;
  } */

	.form2_pic p {
		font-size: 1rem;
		font-weight: 800;
		font-family: 'Darker Grotesque', sans-serif;
	}

	.form2_main {
		box-sizing: border-box;
	}

	.choose_pkg {
		display: flex;
		justify-content: center;
		text-align: center;
		margin-top: 3%;
	}

	/* .choose_pkg p {
    font-size: 1.5rem;
    color: #929292;
  } */
	/* .main_con {
    height: 45vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #f7fcff;
    margin-top: 6%;
  } */

	/* .list {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #929292;
    font-family: GalanoGrotesque-Medium;
  } */

	#ofnn,
	#tznn,
	#fnnn {
		width: 60%;
	}

	.tick {
		width: 15%;
	}

	.bttn2 {
		background: #518099;
	}

	.bttn3 {
		background: #333333;
	}

	.reg_process {
		display: flex;
		justify-content: center;
		margin-top: 2%;
	}

	.round {
		width: 8%;
	}

	.klarna label,
	.amazon_pay label,
	.paypal label {
		font-size: 0.85rem;
		color: #333333;
	}

	.voucher {
		display: flex;
		justify-content: center;
	}

	.voucher p {
		color: #333333;
		font-size: 1.7rem;
		font-weight: 500;
	}

	.enter_voucher {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.enter_voucher p {
		background: #f7fcff;
		display: flex;
		justify-content: center;
		padding: 2% 15vw;
		color: #bbbbbb;
		border-radius: 8px;
	}

	.continue {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.list li {
		font-size: 1rem;
	}
}

/* copy */
.container {
	max-width: 1440px;
	margin: auto;
}

.form2_main {
	box-sizing: border-box;
}

.form_pic_con {
	margin: auto;
	margin-top: 2%;
}

.form3_pic {
	height: 100%;
	width: 100%;
	background: url("../../../public/Images/form3pic.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0 50%;
	color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 30px;
	padding: 9rem 0;
	text-align: center;
}

.form3_pic p {
	font-size: 2.5rem;
	font-weight: 800;
	font-family: GalanoGrotesqueAlt-Bold, sans-serif;
	color: #ffffff;
}

.conditionContainer {}


.accept {
	display: flex;
	/* justify-content: center; */
	font-size: 18px;
	padding-top: 10px;
	font-family: GalanoGrotesque-Medium;
	color: #333333;
	line-height: 1rem;
	font-weight: 600;
}

.checkbox {
	width: 80%;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	margin-top: 2%;
	margin-left: 1%;
}

.f3_label {
	color: #8CC9E9;
	font-family: GalanoGrotesque-Medium;
	display: flex;
	flex-direction: row;
	/* justify-content: center; */
	align-items: center;
	font-size: 1rem;
}

.span {
	width: 90%;
	padding-left: 1.8rem;
	font-family: GalanoGrotesque-Medium;
	text-decoration: underline;
	text-decoration-color: #8CC9E9;
	text-decoration-thickness: 1px;
	text-underline-offset: 2px;
}

.acc_nsubmit {
	margin: auto;
	display: flex;
	justify-content: space-between;
	margin-top: 2.5rem;
	width: 55%;

}

.acc_nsubmit button {
	padding: 0.8rem 1rem;
	display: flex;
	justify-content: center;
	background: #333333;
	color: #ffffff;
	border: none;
	font-family: GalanoGrotesque-Regular;
	font-size: 1rem;
	font-weight: 500;
	border-radius: 5px
}

.cancel {
	display: flex;
	justify-content: center;
	margin-top: 0.85rem;
}

.cancel button {
	width: 22%;
	padding: 1rem 0rem;
	display: flex;
	justify-content: center;
	background: #c7c7c7;
	color: #ffffff;
	border: none;
	font-family: GalanoGrotesque-Medium;
	font-size: 1.2rem;
	font-weight: 500;
	border-radius: 5px;
}

button:hover {
	background: #333333;
}

.f3_label {
	color: #8CC9E9;
	font-family: GalanoGrotesque-Medium;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1.2rem;
}

.f3_input {
	height: 1.5rem;
	width: 1.5rem !important;
	transform: scale(1);
	appearance: none;
	-webkit-appearance: none;
	border: 1px solid #BFBFBF;
	border-radius: 4px;
	background-color: white;
	margin-right: 0.5rem;
}

.f3_input:checked {
	background-color: white;
	border-color: #BFBFBF;
}

.f3_input:checked::before {
	content: "\2713";
	display: block;
	text-align: center;
	font-size: 0.9vw;
	font-weight: 900;
	color: black;
	margin: auto;
}

.modelContent {
	max-width: 20rem !important;
}



@media screen and (max-width:767px) {
	.conditionContainer {
		width: 80%;
		margin: 0 auto;
	}

	.heading {
		font-family: 'MontserratBold';
		font-size: 1.2rem;
		margin-left: -70px;
	}

	.accept {
		/* background: red; */
		margin-bottom: 1.5rem;
	}

	/* .accept{
      padding: 3vw;
      font-size: 5vw;
      word-wrap: break-word;
      text-align: center;
      line-height: 7vw;
    } */
	.checkbox {
		width: 100%;
	}

	.f3_input {
		width: 5vw !important;
		height: 4.5vw;
		/* transform: scale(1.5);  */
	}

	.f3_input:checked::before {
		font-size: 2.8vw;
		/* Adjust the checkmark size */
	}

	.span {
		width: 100%;
		padding-left: 4vw;
		font-size: 16px;
		text-align: left;
	}

	.acc_nsubmit {
		display: flex;
		justify-content: space-between;
		padding: 0%;
		margin-top: 2rem;
		/*  */
	}

	.acc_nsubmit button {
		padding: 0.5rem 1rem;
		font-size: 0.6rem;
		font-family: GalanoGrotesqueAltHeavy-Medium;
		border-radius: 5px;
		font-weight: 500;

	}

	.cancel button {
		width: 50%;
		font-size: 3vw;
	}

	.acc_nsubmit {
		padding: 0px 20px;
		width: 80%;
	}

	.modelContent {
		max-width: 18rem !important;
	}
}

@media screen and (min-width:768px) and (max-width:1023px) {

	/* .form_pic_con {
      padding: 0 3.5rem;
      margin-left: 2%;
    } */
	.form3_pic {
		padding: 5rem 0;
	}

	.form3_pic p {
		font-size: 2rem;
		font-weight: 900;
	}

	.accept {
		font-size: 0.9rem;
		margin-top: 5%;
	}

	.checkbox {
		/* width: 50%; */
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-top: 4%;
		/* margin-left: 16.5%; */

	}

	.f3_label {
		font-size: 0.9rem;
	}

	.f3_input {
		width: 2%;
		/* transform: scale(3);  */
	}

	.acc_nsubmit {
		width: 80%;
	}

	.acc_nsubmit button {
		width: 30%;
		padding: 1rem 0rem;
		font-size: 1.2rem;

	}

	.cancel button {
		width: 30%;
		padding: 1rem 0rem;
		font-size: 1.2rem;
	}
}

@media screen and (min-width:1024px) and (max-width:1365px) {

	/* .form_pic_con {
      padding: 0 5rem;
      margin-left: 2%;
    } */
	.form3_pic {
		border-radius: 30px;
		padding: 6rem 0;
	}

	.form3_pic p {
		font-size: 2.5rem;
	}

	.accept {
		font-size: 1.2rem;
		margin-top: 5%;
	}

	.checkbox {
		/* width: 50%; */
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-top: 4%;
		/* margin-left: 24%; */

	}

	.f3_label {
		font-size: 1.4rem;
	}

	.f3_input {
		width: 2%;
		/* transform: scale(3);  */
	}

	/* .acc_nsubmit button {
		width: 30%;
		padding: 1rem 0rem;
		font-size: 1.2rem;
		
	} */

	.cancel button {
		width: 30%;
		padding: 1rem 0rem;
		font-size: 1.2rem;
	}
}