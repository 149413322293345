@font-face {
  font-family: "GalanoGrotesque-Bold";
  src: url("../fonts/galano-grotesque-alt-cufonfonts/GalanoGrotesqueBold.otf") format("opentype");
}

@font-face {
  font-family: "GalanoGrotesqueAltHeavy";
  src: url("../fonts/galano-grotesque-alt-cufonfonts/GalanoGrotesqueAltHeavy.otf") format("opentype");
}

@font-face {
  font-family: "GalanoGrotesque-ExtraBold";
  src: url("../fonts/galano-grotesque-alt-cufonfonts/GalanoGrotesqueAltExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "GalanoGrotesque-ExtraBold";
  src: url("../fonts/galano-grotesque-alt-cufonfonts/GalanoGrotesqueAltExtraBold.otf") format("opentype");
}


@font-face {
  font-family: "GalanoGrotesque-Medium";
  src: url("../fonts/galano-grotesque-alt-cufonfonts/GalanoGrotesqueMedium.otf") format("opentype");
}

@font-face {
  font-family: "GalanoGrotesqueAlt-Bold";
  src: url("../fonts/galano-grotesque-alt-cufonfonts/GalanoGrotesqueAltBold.otf") format("opentype");
}

@font-face {
  font-family: "GalanoGrotesque-Black";
  src: url("../fonts/galano-grotesque-alt-cufonfonts/GalanoGrotesqueBlack.otf") format("opentype");
}

@font-face {
  font-family: "GalanoGrotesque-SemiBold";
  src: url("../fonts/galano-grotesque-alt-cufonfonts/GalanoGrotesqueSemiBold.otf") format("opentype");
}

@font-face {
  font-family: "GalanoGrotesque-Regular";
  src: url("../fonts/galano-grotesque-alt-cufonfonts/GalanoGrotesqueRegular.otf") format("opentype");
}

@font-face {
  font-family: "GalanoGrotesqueAltLight";
  src: url("../fonts/galano-grotesque-alt-cufonfonts/GalanoGrotesqueAltLight.otf") format("opentype");
}


@font-face {
  font-family: "NothingCanDo";
  src: url("../fonts/NothingYouCouldDo/NothingYouCouldDo-Regular.ttf")
}

/* Montserrat fonts */

@font-face {
  font-family: "MontserratBold";
  src: url("../fonts/Montserrat/MontserratBold.ttf");
}

@font-face {
  font-family: "MontserratRegular";
  src: url("../fonts/Montserrat/MontserratRegular.ttf");
}

@font-face {
  font-family: "MontserratMedium";
  src: url("../fonts/Montserrat/MontserratMedium.ttf");
}

@font-face {
  font-family: "MontserratLight";
  src: url("../fonts/Montserrat/MontserratLight.ttf");
}

@font-face {
  font-family: "MontserratExtraBold";
  src: url("../fonts/Montserrat/MontserratExtraBold.ttf");
}

@font-face {
  font-family: "MontserratThin";
  src: url("../fonts/Montserrat/MontserratThin.ttf");
}