.banner {
    width: 60%;
    text-align: center;
    background: #333333;
    color: #ffffff;
    margin: 5.6% auto;
    padding: 1%;
    box-sizing: border-box;
    border-radius: 30px / 30px;
}

.bannerTitle {
    font-size: 2rem;
    font-weight: 800;
    line-height: 3.5rem;
    font-family: "GalanoGrotesqueAlt-Bold"
}

@media screen and (max-width: 767px) {

    .banner {
        width: 80%;
        border-radius: 10px 10px;
    }

    .bannerSubTitle {
        font-size: 0.65rem;
    }

    .bannerTitle {
        font-size: 0.8rem;
        line-height: 1rem;
    }
}

@media screen and (max-width: 480px) {
 .banner{
    padding: 3%;
 }

}