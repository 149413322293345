.main{
    margin: auto;
    width: 40%;
}



@media screen and (max-width: 1200px)  {
    .main{
        width: 60%;
    }  
}

@media screen and (max-width: 768px)  {
    .main{
        width: 100%;
    }  
}
