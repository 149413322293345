.storyContainer {
    background-color: #333333;

    /* Dark background color */
    color: #fff;
    padding: 2rem;
    border-radius: 1.5rem;
    max-width: 60rem;
    /* min-height: 22rem; */
    margin: auto;
    position: relative;
}


.titleContainer {
    position: absolute;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: #8CC9E9;
    /* Light blue background for title */
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    min-width: 15rem;
}

.storyTitle {
    font-size: 1.5rem;
    color: #fff;
    margin: 0;
    font-family: 'GalanoGrotesque-SemiBold';
}

.storyText {
    /* line-height: 1.6; */
    margin-top: 1.5rem;
    font-size: 1.125em;
    /* width: 55rem; */
    text-align: center;
    font-family: 'GalanoGrotesque-Medium';
}

.highlight {
    font-family: 'GalanoGrotesque-SemiBold';
    color: #fff;
}

.readMore {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 0.9rem;
    text-decoration: none;
    cursor: pointer;
    font-family: 'GalanoGrotesque-SemiBold';
    text-decoration: underline;
}




/* Responsive styling */
@media (max-width: 768px) {
    .storyContainer {
        padding: 1.5rem;
    }

    .storyTitle {
        font-size: 1.1rem;
    }

    .storyText {
        font-size: 1rem;
    }

    .readMore {
        font-size: 1rem;
    }

    .titleContainer {
        top: -1.7rem;
        min-width: 12rem;
    }
}