.main {

    display: flex;
    flex-direction: column;
    max-width: 1400px !important;
    margin: auto;
}

.privacyContainer {
    max-width: 1640px;
    /* margin: 0 auto; */
    margin-left: .5rem;
    padding: 10px;
    font-family:'GalanoGrotesque-Regular';
    color: #757f86;
    line-height: 1.6;


}

.mainHeading {
    /* font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem; */

    color: #333;
    /* font: normal 23px / 30px 'Galano-Grotesque-DEMO-Bold'; */
    font-size: 25px;
    font-weight: bold;
    padding-top: 15px;
}

.sectionHeading {
    font-size: 1rem;
    margin-top: 1rem;
    color: #757f86;
}

.subsectionHeading {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 1rem;
    color: #757f86;
}

.sublist {
    list-style-type: none;
    padding-left: 1.5rem;
    margin-top: 0.5rem;
    color: #757f86;
}

.sublist li {
    margin-bottom: 0.5rem;
}

/* .sublist li::before {
    content: "•";
    color: #007acc;
    font-weight: bold;
    display: inline-block;
    width: 1rem;
    margin-left: -1.5rem;
} */

.nested-sublist li::before {
    line-height: 1.6;
    content: "•";
    font-weight: bold;
    display: inline-block;
    width: 1rem;
    margin-left: -1.5rem;
}

.nestedSublist {
    list-style-type: none;
    padding-left: 1.5rem;
    line-height: 1.6;
    /* Add indentation for nested items */
}

.nestedSublist li {
    margin-bottom: 0.6rem;
    color: #555;
    /* Slightly lighter color for nested items */
}

@media screen and (max-width: 1399px) {
    .main{
    padding: 2rem;
    }
}
/* Responsiveness for smaller screens */
@media screen and (max-width: 768px) {
    .privacyContainer {
        padding: 15px;
    }

    .mainHeading {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    .sectionHeading {
        font-size: 1.3rem;
    }

    .subsectionHeading {
        font-size: 1.1rem;
    }

    .sublist li {
        font-size: 0.95rem;
    }
}

/* Responsiveness for extra-small screens */
@media screen and (max-width: 480px) {
    .privacyContainer {
        padding: 10px;
    }

    .mainHeading {
        font-size: 1.5rem;
    }

    .sectionHeading {
        font-size: 1.2rem;
    }

    .sublist {
        padding-left: 1rem;
    }

    .sublist li {
        font-size: 0.9rem;
    }
}