.banner {
  margin-top: 3rem;
  position: relative;
  padding-left: 2rem;
  padding-right: 2rem;
}

.promoCard {
  position: absolute;
  bottom: 3.125rem;
  left: -1rem;
  background-color: #8cc9e9;
  color: #000;
  width: 35rem;
  padding-left: 4rem;
  padding-top: 1.25rem;
  padding-bottom: 2.188rem;
  border-radius: 2.188rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.heroImg {
  border-radius: 2rem;
}

.promoCard h1 {
  font-size: 2.188rem;
  margin-bottom: 10px;
  line-height: 1.3;
  font-family: "GalanoGrotesque-SemiBold";
  text-align: left;
}

.promoCard p {
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
  font-family: "GalanoGrotesque-Medium";
  text-align: left;
}

.btnsection {
  display: flex;
  flex-direction: row;
  gap: 0.313rem;
}

.storeButton {
  width: 11.875;
  height: 3.517rem;
}

.promoCardMobile {
  display: none;
}

@media screen and (max-width: 1224px) {
  .promoCard {
    position: absolute;
    bottom: 3.125rem;
    left: -1rem;
    background-color: #8cc9e9;
    color: #000;
    width: 30rem;
    padding-left: 3rem;
    padding-top: 1.25rem;
    padding-bottom: 2.188rem;
    border-bottom-right-radius: 2.188rem;
    border-top-right-radius: 2.188rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .promoCard h1 {
    font-size: 2rem;
    margin-bottom: 5px;
    line-height: 1.3;
    text-align: left;
  }

  .promoCard p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    text-align: left;
  }

  .storeButton {
    width: 9rem;
    height: 2.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .promoCard {
    position: absolute;
    bottom: 3.125rem;
    left: -1rem;
    background-color: #8cc9e9;
    color: #000;
    width: 25rem;
    padding-left: 2rem;
    padding-top: 1.25rem;
    padding-bottom: 2.188rem;
    border-bottom-right-radius: 2.188rem;
    border-top-right-radius: 2.188rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .promoCard h1 {
    font-size: 1.7rem;
    margin-bottom: 5px;
    line-height: 1.3;
    text-align: left;
  }

  .promoCard p {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    text-align: left;
  }

  .storeButton {
    width: 7rem;
    height: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .banner {
    margin-top: 3rem;
    position: sticky;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .promoCard {
    display: none;
  }

  .promoCardMobile {
    display: block;
    background-color: #8cc9e9;
    color: #000;
    width: 100%;
    margin-top: 2rem;
    padding-bottom: 0rem;
    border-radius: 60px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 2rem;
  }

  .promoCardMobile h1 {
    font-size: 1.5rem;
    line-height: 1;
    text-align: center;
    padding-top: 0.5rem;
    font-family: "GalanoGrotesque-Bold";
  }

  .promoCardMobile p {
    margin-top: 1.2rem;
    font-size: 1rem;
    text-align: center;
    font-family: "GalanoGrotesque-Medium";
  }

  .btnsectionMobile {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .storeButtonMobile {
    width: 6rem;
    height: auto;
  }
}
