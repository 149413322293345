.aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.aboutTitle {
    font-size: 2.188rem;
    font-family: 'GalanoGrotesque-SemiBold';
    margin-bottom: 2rem;
    text-align: center;
}

.teamMembers {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
}

.aboutStorySection {
    margin-top: 3rem;
    max-width: 60rem;

}

.outGoalMargin {
    margin-top: 8rem;
    max-width: 60rem;
}

.marginStartNetwork {
    margin-top: 20rem;
}

.startNetwork {
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}

.startNetwork p {
    cursor: pointer;
    background-color: #000000;
    width: auto;
    padding: 10px 20px;
    font-size: 1.5rem;
    font-family: 'GalanoGrotesque-SemiBold';
    color: #8CC9E9;
    border-radius: 1rem;
    text-align: center;

}

.letsNetmeImage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.letsNetmeImage img {

    width: 900px;
    height: 550px;
}

.footerMargin {
    margin-top: 1.375rem;
}

.footerText {
    font-size: 2.188rem;
    font-family: 'NothingCanDo';

}

@media (max-width: 820px) {
    .outGoalMargin {
        margin-top: 6rem;
    }

    .letsNetmeImage img {

        width: 500px;
        height: auto;
    }

    .startNetwork p {

        /* padding: 10px 15px; */
        padding: 17px 30px;
        font-size: 1rem;
        font-family: 'GalanoGrotesque-SemiBold';
        color: #8CC9E9;
        border-radius: 1rem;
        text-align: center;

    }

    .aboutTitle {
        font-size: 1.5rem;
    }
}