 .main {
    width: 100%;
    display: flex;
 }

 .goalText {
    width: 50%;
    display: flex;
    justify-content: left;
    align-items: center;
 }

 .goalText p {
    width: 80%;
    text-align: left;
    font-size: 1.125rem;
    color: #000000;
    font-family: 'GalanoGrotesque-Medium';
 }



 .goalImage {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
 }

 .goalTitle {
    background-color: #8CC9E9;
    color: #333;
    font-size: 1.5rem;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    text-align: center;
    width: 16rem;
    font-family: 'GalanoGrotesque-SemiBold';
 }

 .goalImage img {
    width: 16rem;
    height: auto;
    margin-right: -1rem;
 }

 @media (max-width: 768px) {
    .main {
       width: 100%;
       display: flex;
       flex-direction: column-reverse;
       gap: 2rem;
    }

    .goalText {
       width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
    }

    .goalImage {
       width: 100%;
       height: 50%;
       display: flex;
       flex-direction: column;
       align-items: center;
    }

    .goalImage img {
       width: 12rem;
       height: auto;
       margin-right: -1.5rem;
       margin-top: .5rem;
    }

    .goalText p {
       width: 100%;
       text-align: center;
       font-size: 1.125rem;
       color: #000000;
       font-family: 'GalanoGrotesque-Medium';
    }

 }