.visionContainer {
    display: flex;
    flex-direction: row;
    /* padding: 20px; */
    justify-content: space-between;
    /* align-items: center; */
    gap: 1rem;

}


.visionTitle {
    font-size: 1.5rem;
    font-family: 'GalanoGrotesque-SemiBold';
    background-color: #8CC9E9;
    color: #333;
 text-align: center;
    /* Light blue background */
    padding: 5px 20px;
    border-radius: 0.625rem;
    margin-bottom: 1.25rem;
    /* margin-left: 3rem; */
    width: 13rem;

}

.visionContent {
    display: flex;
    /* align-items: center; */
    margin-left: 15rem;
    gap: 20px;
}

.visionImage img {
    width: 35rem;
    height: auto;
    margin-left: -2.5rem;
}

.visionContainerRight{
    display: flex;
    align-items: center;
    justify-content: center;
}
.visionText {
    max-width: 29rem;
    text-align: right;
    font-size: 1.125rem;
    color: #000000;
    font-family: 'GalanoGrotesque-Medium';
}

/* Responsive Design */
@media (max-width: 1220px) {
    .visionContainer {
        display: flex;
        flex-direction: row;
        /* padding: 20px; */

        justify-content: center;
        align-items: center;
        gap: 0rem;
    }

    .visionText {
        max-width: 29rem;
        text-align: center;
        font-size: 1.125rem;
        /* justify-content: center; */
        color: #000000;
    }
}


@media (max-width: 768px) {
    .visionContainer {
        flex-direction: column;
    }

    .visionContainerLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .visionTitle {
        background-color: #8CC9E9;
        color: #333;

        /* Light blue background */
        padding: 5px 20px;
        border-radius: 0.625rem;
        margin-bottom: 1.25rem;
        margin-left: 0rem;
        width: 13rem;

    }
    .visionImage img {
        margin-left: 0rem;
    }
}