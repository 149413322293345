.main {
  text-align: center;
  margin-top: 1.875rem;
}

.title {
  font-family: "GalanoGrotesque-SemiBold";
  color: #333333;
  font-size: 1.5rem;
}
.recaptcha_btn {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.input {
  width: 28.375rem;
  height: 4.75rem;
  border-radius: 1.25rem;
  font-family: "GalanoGrotesque-Medium";
  color: #333333;
  text-align: center;
  background-color: #f4f4f4;
  margin-top: 0.625rem;
  margin-bottom: 1.875rem;
}

.btn {
  background-color: #333333;
  color: white;
  width: 10.75rem;
  height: 2.625rem;
  border-radius: 1.563rem;
  font-family: "GalanoGrotesque-Medium";
}

@media screen and (max-width: 500px) {
  .input {
    width: 15rem;
    height: 3rem;
    border-radius: 1.25rem;
    font-family: "GalanoGrotesque-Medium";
    color: #333333;
    text-align: center;
    background-color: #f4f4f4;
    margin-top: 0.625rem;
    margin-bottom: 1.875rem;
  }
  /* .recaptcha_btn {
    width: 20rem;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  } */
}
