
.container {
    width: 75% !important;
    /* Default width */
    border-radius: 3.125rem;
    height: 12rem;
    background-color: #333333;
    margin-bottom: 30px;
    margin-top: 20px;
    padding: 1.25rem 1.875rem;
    /* margin-left: 5rem; */
    /* Padding all around */
    /* margin: 0 15px; Adds horizontal gap between cards */
    transition: margin 0.3s ease-in-out;
    /* Smooth transition */
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
}

.title {
    color: #333333;
    font-size: 2.188rem;
    font-family: 'GalanoGrotesque-SemiBold';
    text-align: center;
    
}

.box {
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
    /* Space between image and text */
    display: flex;
    align-items: center;
}

.image {
    min-width: 3.5rem;
    min-height: 3.5rem;
    border-radius: 100%;
    background-color: rgb(211, 210, 210);
    display: flex;
    justify-content: center;
    align-items: center;
}

.image p {
    font-size: 1.5rem;
    font-family: 'GalanoGrotesque-SemiBold';
}
.stars{
    display:flex;
     gap:5px;
    margin-top:10px;
     margin-bottom:10px;
}
.detail {
    width: 90%;
    font-size: 1.125rem;
    color: white;
    font-family: 'GalanoGrotesque-Medium';
    text-align: left;
}

.clientName {
    font-size: 1.2rem;
    color: white;
    font-family: 'GalanoGrotesque-SemiBold';
    /* background-color: aqua; */
}
/* Increase dot size */



@media (max-width: 1170px) {
    .container {
        width: 95% !important;
        /* Default width */
        border-radius: 3.125rem;
        height: 15rem;
        background-color: #333333;
        margin-bottom: 30px;
        margin-top: 20px;
        padding: 1.25rem 1.875rem;
        /* margin-left: 5rem; */
        /* Padding all around */
        /* margin: 0 15px; Adds horizontal gap between cards */
        transition: margin 0.3s ease-in-out;
        /* Smooth transition */
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-between !important;
    } 
}
/* Responsive design */
@media (max-width: 790px) {
    .box {
        display: flex;
        flex-direction: column;
    }

    .detail {
        width: 100%;
        font-size: 1.25rem;
        color: white;
        text-align: center;
    }

    .clientName {
        font-size: 1.563rem;
        color: white;
        /* margin-top: 3.125rem; */
        text-align: center;
    }

    /* .image {
        max-width: 6rem;
        max-height: 6rem;
        border-radius: 100%;
        overflow: hidden;
        margin: auto;
    } */

    .container {
        width: 75% !important;
        /* Default width */
        border-radius: 3.125rem;
        height: 15rem;
        background-color: #333333;
        margin-bottom: 30px;
        margin-top: 20px;
        padding: .2rem .5rem;
        margin-left: 2rem;
        /* Padding all around */
        /* margin: 0 15px; Adds horizontal gap between cards */
        transition: margin 0.3s ease-in-out;
        /* Smooth transition */
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
    }
    .stars{
        display:flex;
        justify-content: center;
     gap:5px;
    margin-top:5px;
     margin-bottom:5px;
    }
    .detail {
        width: 100%;
        font-size: 1.25rem;
        color: white;
        text-align: center;
    }

    .clientName {
        font-size: 1rem;
        color: white;
        /* margin-top: 3.125rem; */
    }
}