.container{
     max-width: 1690px;
    margin: auto; 
display: flex; 
flex-direction: column; 
align-items: center;  
margin-top: 5rem;

}
.title{
    font-family: 'GalanoGrotesque-Bold';
    font-size: 2rem;
    color: #333333;
}
.subTitle0{
    font-family: 'GalanoGrotesqueAltLight';
    font-size: 2rem;
    color: #333333;
    margin-top: 7rem;
    text-align: center;
    padding-left: 1rem; ;
    padding-right:1rem ;
    width: 100%;
    text-align: left;
    padding-left: 7rem;
    margin-bottom: 0rem;

    /* background-color: aqua; */
}
.subTitle1{
    margin-top: 1rem;
}
.subTitle1 span{
    font-family: 'GalanoGrotesqueAltLight';
    font-size: 2rem;
    color: #333333;
}

.subTitle1 span:nth-child(odd) {
    font-family: 'GalanoGrotesque-Bold';
  }

.subTitle2{
    font-family: 'GalanoGrotesqueAltLight';
    font-size: 2rem;
    color: #333333;
}
.bottonText{
    padding-top: 5rem;
    padding-bottom: 1rem;
    font-size: 2rem;
    text-align: center;
    font-family: 'NothingCanDo';
}



@media screen and (max-width:1130px){

    .subTitle0{
        font-family: 'GalanoGrotesqueAltLight';
        font-size: 2rem;
        color: #333333;
        margin-top: 4rem;
        margin-bottom: 4rem;
        text-align: center;
        padding-left: 0rem; ;
        padding-right:0rem ;
        width: 100%;
        padding-left: 0rem;
    }
} 


@media screen and (max-width:760px){
    .subTitle0{
        margin-top: 0rem;
        text-align: center;
        padding-left: 0rem; ;
        padding-right:0rem ;
        padding-left: 0rem;
        margin-bottom: 6rem;
        
    }
    .title{
        font-size: 1.5rem;
      text-align: center;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .subTitle1 {
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .subTitle1 span{
        font-size: 1.5rem;
    }
    
    .subTitle2{
        font-size: 1.5rem;
        text-align: center;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 3rem;
    }
    .bottonText{
        padding-top: 3rem;
       
    }
  }
  @media screen and (max-width:500px){

    .subTitle0{
        margin-top: 0rem;
        margin-bottom: 2rem;

        padding-left: 0rem; ;
        padding-right:0rem ;
        width: 100%;
        padding-left: 0rem;
    }
} 