.container {
  height: 400%;
  display: flex;
  flex-direction: column;
  max-width: 1460px !important;
  margin: auto;
  gap: 3.125rem;

}


@media screen and (max-width: 1300px) {
  .container {
    height: 400%;
    display: flex;
    flex-direction: column;
    max-width: 1460px !important;
    margin: auto;
    gap: 3.125rem;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    height: 400%;
    display: flex;
    flex-direction: column;
    max-width: 1460px !important;
    margin: auto;
    gap: 3.125rem;
    padding: 20px;
  }
}