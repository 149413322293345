.container {
    display: flex;
    flex-direction: column;
    max-width: 1400px !important;
    margin: auto;
  }
  @media screen and (max-width: 1399px) {
    .container {
      padding: 2rem;  
    }
  }