.container {
	max-width: 1440px;
	margin: auto;
}

.voucher_prices {
	font-size: 20px;
	font-weight: bold;
	color: #71717A;
}
.buttonIcon{
	width: 11px;
	height: 11px;
}
.voucher_text {
color: #71717A;
font-size: 12px;
}
.voucher_point{
	margin-top:8px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 8px;
}
.mostWatch{
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.mostWatchButton {
	border-radius: 45px;
	height: 25px;
	width: 90px;
	text-align: center;
	padding-top: 4px;
	background: black;
	color: white;
	font-size: 11px;
	font-family: GalanoGrotesqueAltHeavy, sans-serif;

  }
  
.form_pic_con {
	width: 80%;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	/* padding: 2rem 7.5rem; */
	padding: 0 7% 0 8%;
	box-sizing: border-box;
	margin-top: 2%;
}

.form2_pic {
	height: 100%;
	width: 100%;
	background: url('../../../public/Images/form2pic.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
	color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 30px;
	text-align: center;
	padding: 6rem 0;
}

.paging_container{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 55%;
  }

.form2_pic p {
	font-size: 2.8rem;
	font-family: GalanoGrotesqueAltHeavy, sans-serif;
}

.form2_main {
	padding: 2rem 2rem;
	box-sizing: border-box;
	border: 1px solid lightgray;
	margin: 1.5rem;
	border-radius: 10px;
}

.choose_pkg {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.one_x {
	font-size: 0.85rem;
	color: #333333;
	line-height: 0.1rem;
	margin: 0.5rem 0;
}

.choose_pkg p {
	font-size: 16px;
	font-weight: 400;
	font-family: GalanoGrotesque-Medium;
	color: #929292;
}

.main_con {
	width: 100%;
	display: flex;
}

.list {
	margin-top: 5rem;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	color: #929292;
	font-family: GalanoGrotesque-Medium;
}

.list li {
	font-size: 1.2rem;
	font-family: GalanoGrotesque-Medium;
	margin-bottom: 0;
}

.listItem {
	display: flex;
	gap: 4%;
	align-items: center;
}

.tooltip {
	width: 4% !important;
}

.tooltip img {
	margin-top: 0 !important;
}

.txt_pic {
	height: 100%;
	width: 35%;
}

.txt_pic>img {
	width: 14%;
	margin-top: 1rem;
}

.dash {
	width: 10%;
	margin: 0.5rem 0;
}

.maincards_div {
	width: 100%;
	padding: 1.5rem;
	border-radius: 49px;
	background: #ffffff;
}

.cards_div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 312px;
}

.card1,
.card2,
.card3 {
    width: 32%;
    border-radius: 10px;
    padding: 1.5rem ;
}


.innerCard {
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: start;
	margin-bottom: 10px;

	/* justify-content: center; */
}

.innerCard p {
	font-size: 1.5rem;
	color: #333333;
	font-family: GalanoGrotesque-Medium, sans-serif;
}

.card3,
.card2,
.card1 {
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

#ofnn,
#tznn,
#fnnn {
	width: 60%;
	margin-top: 3%;
}

.tick {
	width: 15px;
	height: 15px;
}

.bttn,
.bttn2,
.bttn3 {
	padding: 0.6rem;
	border-radius: 8px;
	border: none;
	background: #8dc3df;
	margin-left: 1.3rem;
	margin-right: 1.3rem;
	color: #ffffff;
	font-size: 1.2rem;
	margin-top: 2.5rem;
	margin-bottom: 1rem;
	font-family: GalanoGrotesque-Bold;
}

.bttn:hover {
	background: #8dc3df;
}

.bttn2:hover {
	background: #518099;
}

.bttn3:hover {
	background: #333333;
}

/* .bttn {
  margin-top: 1.4rem;
} */
.bttn2 {
	background: #518099;
}

.bttn3 {
	background: #333333;
}

.headingPayment {
	font-size: 24px;
	color: #333333;
	letter-spacing: 0.06rem;
	font-family: GalanoGrotesque-Medium;
	font-weight: 600;
}
.subHeadingPayment {
	font-size: 14px;
	color: #71717A;

}
.round {
	width: 8%;
}

.payment_lane {
	display: flex;
	justify-content: center;
}

.klarna {
	margin: 0px 5px;
}

.klarna,
.amazon_pay,
.paypal {
	padding: 5px 20px;
	border: 1px solid lightgray;
	display: flex;
	align-items: center;
	border-radius: 5px;
	margin-top: 10px;
	height: 87px;
	width: 50%;
	justify-content: center;
}

.klarna label,
.amazon_pay label,
.paypal label {
	font-size: 1.2rem;
	font-family: GalanoGrotesque-Medium, sans-serif;
	color: #333333;
}
.selected {
	border: 2px solid black; 
  }
  

.asidLeft {
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
}

.card_box {
	background: #fff;
	border-radius: 10px;
	border: 1px solid #ccc;
	padding: 10px;
	margin: 0 auto;
}

.contentBox {
	padding: 10px 0px;
}

.contentBox p {
	font-size: 18px;
	text-align: left;
}

.cardFooter {
	padding: 10px 0px;
	border-top: 1px solid #ccc;
}

.icon_pic {
	/* width: 30%; */
	width: 80px;
}

.voucher {
	display: flex;
}

.voucher p {
	color: #09090B;
	font-size: 14px;
	margin-bottom: 10px;
	font-family: GalanoGrotesque-Medium;
	font-weight: 500;
}

.enter_voucher {
	display: flex;
	align-items: center;
	gap: 10px;
}

.enter_voucher input {
	border: 1px solid lightgray;
	display: flex;
	padding: 0.8rem 1rem;
	color: #bbbbbb;
	border-radius: 6px;
	width: 100%;
}

input[type='text'] {
	color: #333333;
}

.remove_voucher {
	color: red;
	font-size: xx-large;
	cursor: pointer;
}

.remove_voucher:hover {
	background: #fff;
}

.continue {
	display: flex;
	justify-content: space-between !important;
	align-items: center;
	margin-top: 20px;
}

.continue button {
	padding: 0.8rem 1rem;
	display: flex;
	justify-content: center;
	background: #333333;
	color: #ffffff;
	border: none;
	font-family: GalanoGrotesque-Regular;
	font-size: 14px;
	font-weight: 500;
	border-radius: 5px;

}
.apply {
	display: flex;
	justify-content: space-between !important;
	align-items: center;
}

.apply button {
	padding: 0.8rem 1rem;
	display: flex;
	justify-content: center;
	border: 1px solid lightgray;
	color: #333333;
	font-family: GalanoGrotesque-Regular;
	font-size: 1rem;
	font-weight: 500;
	border-radius: 5px;
}
.apply button:hover {
	border: 1px solid lightgray; 
	color: #333333; 
	background-color: transparent; 
  }

[type='radio']:checked,
[type='radio']:not(:checked) {
	position: absolute;
	left: -9999px;
}

[type='radio']:checked+label,
[type='radio']:not(:checked)+label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: #666;
}

[type='radio']:checked+label:before,
[type='radio']:not(:checked)+label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	border: 1px solid black;
	border-radius: 100%;
	background: #fff;
}

[type='radio']:checked+label:after,
[type='radio']:not(:checked)+label:after {
	content: '';
	width: 12px;
	height: 12px;
	background: black;
	position: absolute;
	top: 4px;
	left: 4px;
	border-radius: 100%;
	transition: all 0.2s ease;
}

[type='radio']:not(:checked)+label:after {
	opacity: 0;
	transform: scale(0);
}

[type='radio']:checked+label:after {
	opacity: 1;
	transform: scale(1);
}

.ant-tooltip {
	background-color: #f0f0f0 !important;
	/* Change tooltip background color */
	color: #333;
	/* Change tooltip text color */
	border: 1px solid #ccc;
	/* Change tooltip border */
}

/* Customize the tooltip arrow */
.ant-tooltip-arrow-content {
	background-color: #f0f0f0;
	/* Change arrow background color */
}

/* Customize the tooltip content */
.ant-tooltip-inner {
	padding: 8px;
	/* Adjust tooltip padding */
	font-size: 14px;
	/* Change tooltip font size */
}

.ant-tooltip-content {
	width: 300px !important;
}

.grid {
	/* display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; */
	display: flex;
	gap: 60px;
	margin: 40px 0px 60px 0px;
	justify-content: center;
	align-items: center;
}

/* .count {
	background-color: #f31010;
	font-family: 'MontserratBold';
	width: 5rem;
	height: 3rem;
	border-radius: .5rem;
	font-size: 1rem;

	display: flex;
	justify-content: center;
	align-items: center;

} */


/* .count {
	background-color: #f7f1f1;
	font-family: 'MontserratBold';
	width: 5rem;
	height: 3rem;
	border-radius: .5rem;
	font-size: 1rem;

	display: flex;
	justify-content: center;
	align-items: center;

	margin-right: auto;
} */

.parentContainer {
	display: flex;
	justify-content: flex-end;
	margin-right: 10rem;
	/* Aligns children to the right */
}

.count {
	background-color: #f7f1f1;
	font-family: 'MontserratBold';
	width: 5rem;
	height: 3rem;
	border-radius: .5rem;
	font-size: 1rem;

	display: flex;
	justify-content: center;
	align-items: center;
}


@media screen and (max-width: 767px) {
	.grid {
		flex-direction: column;
		gap: 10px;
	}
	.mostWatch{
		width: 100%;
		display: flex;
		justify-content: center;
		gap: 8px;
	}

	.paging_container{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 80%;
	  }

	.listItem {
		justify-content: space-between;
	}

	.choose_pkg p {
		font-size: 16px;
	}

	.one_x {
		font-size: 2vw;
		color: #333333;
		line-height: 0.1rem;
		margin: 0.5rem 0;
	}

	.reg_process p {
		font-size: 4vw;
	}

	.form2_main {
		padding: 3vw;
		text-align: center;
		word-wrap: break-word;
	}
	.voucher_point{
		margin-top:8px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: start;
		gap: 8px;
	}

	
	.main_con {
		width: 100%;
		display: flex;
		justify-items: center;
		align-items: center;
		justify-content: space-between;
		margin-top: 2%;
		padding: 5vw;
		text-align: left;
		flex-direction: column;
	}

	.txt_pic {
		width: 100%;
	}

	.txt_pic img {
		width: 15%;
		margin: 0px;
	}

	.list {
		width: 100%;
		margin-top: 2vw;
		gap: 1vw;
		padding: 1vw;
	}

	.list li {
		font-size: 0.8rem;
	}

	.maincards_div {

		width: 100%;
		padding: 1vw;
		border-radius: 12px;
		background: #ffffff;
	}

	.cards_div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 5vw;
		height: 100%;
		flex-direction: column;

	}

	.card1,
	.card2,
	.card3 {
		width: 100%;
		border-radius: 12px;
		padding: 20px 60px ;

	}

	.innerCard {
		display: flex;
		height: 100%;
		flex-direction: column;
		align-items: center;
	}

	.innerCard p {
		font-size: 2vw;
		color: #333333;
		font-family: GalanoGrotesque-Medium, sans-serif;
	}

	.bttn,
	.bttn2,
	.bttn3 {
		width: 100%;
		padding: 0.5vw;
		border-radius: 8px;
		border: none;
		background: #8dc3df;
		margin-left: 2vw;
		margin-right: 0;
		font-size: 1vw;
		margin-top: 0;
		margin-bottom: 2vw;
	}

	.payment_lane {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 5vw;
	}

	.klarna,
	.amazon_pay,
	.paypal {
		padding: 5px 20px;
		border: 1px solid lightgray;
		display: flex;
		align-items: center;
		border-radius: 5px;
		margin-top: 10px;
		height: 87px;
		width: 90%;
		justify-content: center;
	}

	.klarna label,
	.amazon_pay label,
	.paypal label {
		font-size: 4vw;
		font-family: GalanoGrotesque-Medium, sans-serif;
		color: #333333;
	}
	.choose_pkg {
		display: flex;
		justify-content: center;
		text-align: center;
		margin-bottom: 30px;
	}



	.continue button {
		padding: 0.5rem 1rem;
		font-size: 0.6rem;
		font-family: GalanoGrotesqueAltHeavy-Medium;
		border-radius: 5px;
		font-weight: 500;
	}

	.tooltip {
		width: 8% !important;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.form_pic_con {
		padding: 0 5.5rem;
	}

	.form2_pic {
		border-radius: 30px;
		padding: 4rem 0;
	}

	/* #fpt2 {
    font-size: 1rem;
    margin-top: 2%;
    font-weight: 900;
    font-family: GalanoGrotesqueAlt-Black;
  } */

	.form2_pic p {
		font-size: 2rem;
		font-weight: 800;
		font-family: GalanoGrotesqueAlt-Black;
	}

	.form2_main {
		padding: 2rem 2.5rem;
		box-sizing: border-box;
	}






	/* .choose_pkg p {
    font-size: 1.5rem;
    font-family: GalanoGrotesque-Medium;
    color: #929292;
  } */
	/* .main_con {
    height: 60vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #f7fcff;
    margin-top: 6%;
    padding: 2rem;
  } */

	/* .list {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #929292;
    font-size: 0.8rem;
    font-family: GalanoGrotesque-Medium;
  } */

	#ofnn,
	#tznn,
	#fnnn {
		width: 60%;
	}

	.bttn,
	.bttn2,
	.bttn3 {
		padding: 0.5rem 1.5rem;
		border-radius: 8px;
		margin-left: 0;
		margin-right: 0;
		border: none;
		background: #8dc3df;
		color: #ffffff;
		font-size: 0.75rem;
		font-family: GalanoGrotesque-Bold;
	}

	/* .bttn {
    margin-top: 1.4rem;
  } */
	.bttn2 {
		background: #518099;
	}

	.bttn3 {
		background: #333333;
	}

	.reg_process {
		display: flex;
		justify-content: center;
		margin-top: 2%;
	}

	.round {
		width: 8%;
	}

	.klarna label,
	.amazon_pay label,
	.paypal label {
		font-size: 0.85rem;
		color: #333333;
	}

	.voucher {
		display: flex;
	}

	.voucher p {
		color: #333333;
		font-size: 1.2rem;
		font-weight: 600;
	}

	.enter_voucher {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}

	.enter_voucher p {
		background: #f7fcff;
		display: flex;
		justify-content: center;
		padding: 2% 15vw;
		color: #bbbbbb;
		border-radius: 8px;
	}

	.continue {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.list li {
		font-size: 0.8rem;
	}

	.grid {
		grid-template-columns: 1fr 1fr !important;
	}
}

@media screen and (max-width: 1024px) {
	.grid {
		grid-template-columns: 1fr 1fr 1fr;
	}
}