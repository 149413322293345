.uploadContainer {
    width: 52%;
    margin: auto;
    background-color: yellow !important;
}

.preview {
    /* position: relative; */
    width: 30%;
    /* margin: auto; */
    margin-right: 50rem;
}

.deleteIcon {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
}

.deleteIcon:hover {
    background-color: wheat;
}

.formImage {
    /* background-color: rgb(95, 27, 221); */
    margin-top: 0.5rem;
    width: 18rem;
    height: 55px;
    /*height: 6rem; */
    /* margin-bottom: 5rem; */

}

.imageFormText {
    /* background-color: red; */
    /* display: flex;
    flex-direction: column;
    justify-content: left; */
    margin-bottom: 2.5rem;
    margin-top: -0.9rem;
    /* margin-right: 2.5rem; */
}

.icon {
    color: #40a9ff;

}

.title {
    /* background-color: rgb(18, 170, 44); */
    font-size: 1rem;
}

.description {
    font-size: 0.35rem;
}

@media (max-width: 768px) {
    .uploadContainer {
        width: 90%;
    }

    .preview {
        position: relative;
        width: 60%;
        margin: auto;
    }
}