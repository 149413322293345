.Container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.main {
  width: 50rem;
  background-color: #333333;
  border-radius: 1.25rem;
  text-align: left;
  padding: 1.875rem;
}

.title {
  color: #8cc9e9;
  font-family: "GalanoGrotesque-SemiBold";
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}

.detail {
  font-size: 1.125rem;
  font-family: "GalanoGrotesque-Medium";
  color: white;
}

.btnBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.btnsection {
  display: flex;
  /* Arrange buttons horizontally */
  justify-content: center;
  /* Center the buttons */
  align-items: center;
  gap: 10px;
}

.storeButton {
  width: 150px;
  /* Adjust width as per your requirement */
  height: auto;
  /* Maintain aspect ratio */
}

@media screen and (max-width: 650px) {
  .title {
    color: #8cc9e9;
    font-size: 2rem;
    margin-bottom: 1.25rem;
    text-align: center;
  }

  .main {
    padding: 2rem;
  }

  .detail {
    font-size: 1rem;
    color: white;
    text-align: center;
  }

  .btnBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.25rem;
    margin-bottom: 0rem;
  }

  .btnsection {
    margin-top: 1rem;
  }
}
