.main{
    max-width: 1400px !important;
    margin: auto; 
    /* padding-top: 4rem; */
 padding: 50px 25px;
}
.container{
    width: 100%;
  }
  .wrapper{
    overflow: hidden;
  }
  .question-content{
    font-family: 'GalanoGrotesque-SemiBold'

  }
  .wrapper .question-container{
    width: 100%;
    text-align: left;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    cursor: pointer;
    border-bottom: 1px solid rgb(0, 0, 0);
     font-size: 17.5px;

  }

  .mainTitle1{
    font-size: 2rem;
    margin-bottom: 1.5rem;
        font-family: 'GalanoGrotesque-SemiBold';
        color: #333;
}
.mainTitle2{
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
    color: #333;
        font-family: 'GalanoGrotesque-SemiBold';
        /* background-color:red; */
        }
        .seacrhBox{
            border : 1px solid black;
            margin-bottom: 1.5rem;    
        height: 3.2rem;
        display: flex;
        flex-direction: row;
        border-radius: 5px;
        
        }
        .seacrhBoxIcon{
        /* font-size: 2rem; */ 
        padding:10px;
        height: 3.1rem;
           width: 3.1rem;
           color: #333;
           /* background-color: #8c9297; */
           margin-left: 1rem;
        }
        .seacrhBoxInput{
           height: 100%;
           width: 95%;
           border-radius: 5px;
        padding: 0px 13px;
        outline: none;
        font-size: 1.2rem;
        color: #cac5c5;
        font-family: 'GalanoGrotesque-Medium';
        }    
.sing{
color: #8c9297;
font-size: 30px;
font-family: 'GalanoGrotesque-Medium';
height: 20px;
}
.noData{
  font-size: 1.125rem;
  font-family: 'GalanoGrotesque-Medium'; 
}
  
  .wrapper .arrow{
    font-size: 2rem;
    transition: .5s ease-in-out;
  }
  .arrow.active{
    rotate: 180deg;
    color: #1db954;
  }
  .wrapper .answer-container{
    transition: height .5s ease-in-out;
    line-height: 1.8;
  }
  .wrapper .answer-content{
    padding: 1rem 0;
    font-size: 15.5px;
    font-family : 'GalanoGrotesque-Medium';
        color:#8c9297;
  }
  @media screen and (max-width: 650px) {
    .main{
        padding: 50px 15px;
    
    }
  }