.teamMember {
  max-width: 350px;
  height: 350px;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.memberPhoto {
  /* width: 200px;
    height: 200px; */
  /* border-radius: 50%; */
  /* overflow: hidden; */
  /* display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 200px; 
    height: 200px; 
    overflow: hidden; 
    position: relative; */
  /* width: 200px;
    height: 200px;
    position: relative; */
}

.memberPhoto {
  max-width: 250px;
  height: auto;
  clip-path: circle(45% at 50% 50%);
  /* object-fit: contain;  */
}

.memberPhoto1 {
  background-color: blue;
  max-width: 258px;
  height: auto;
  clip-path: circle(44% at 50% 50%);
  /* object-fit: contain;  */
}

.memberName {
  font-size: 1.5rem;
  font-family: "GalanoGrotesque-SemiBold";
  color: rgb(0, 0, 0);
  /*background-color: #77b1fa*/
  /* background-color: #8CC9E9; */
  /* Light blue background */
  /*padding: 0.2rem 0.5rem;*/
  padding: 0rem 1.5rem;
  border-radius: 8px;
  display: inline-block;
}

.memberRole {
  font-size: 0.9rem;
  color: rgb(0, 0, 0);
  margin-bottom: 0.4rem;
  margin-top: 0.2rem;
  /* font-weight: 500; */
  font-family: "GalanoGrotesque-Medium";
}

.memberDescription {
  width: 65%;
  font-size: 1rem;
  color: rgb(0, 0, 0);
  font-family: "GalanoGrotesque-Medium";
}

/* Responsive styling */
@media (max-width: 768px) {
  .teamMembers {
    height: auto;
    flex-direction: column;
    align-items: center;
  }
}
