@import url("./assets/styles/fonts.css");
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

* {
  margin: 0;
  padding: 0;
}

input,
textarea,
select {
  text-size-adjust: none;
}

.slick-dots li button:before{
  font-size: 16px !important;
}

.custom-datepicker {
  /* background-color: red; */
  position: relative;

}
.input-container {
  display: flex;
  align-items: center;
  font-family: GalanoGrotesque-Medium;
  cursor: pointer;
  margin: 1rem;
  margin-left: 1.9rem;
  margin-right: 0rem;
}

.slick-slide{
  padding-left: 0rem;
  margin-right: -5rem;
 display: flex;
 justify-content: center;
 align-items: center;
   /*width: 33rem; */
  /* background-color: aqua; */
}


.date-input {
  flex: 1;
  border: none;
  background: none;
  font-size: 1.5rem;
  /* font-family: Arial, sans-serif; */
  outline: none;
  cursor: pointer;
}

.calendar-icon {
  font-size: 18px;
  color: #f7fcff;
  margin-left: 8px;
}

.calendar-icon:hover {
  color: #f7fcff;
}

.calendar-popup {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px #000000;
  border: 1px solid rgb(132, 171, 192);
  border-radius: 8px;
  z-index: 10;
  padding: 8px;
}



.monthCaptionStyle {
  border-bottom: 1px solid currentColor;
  padding-bottom: 0.5em;
  fill: #e8e8ef;
}


.rdp-root {
  --rdp-accent-color: rgb(0, 0, 0) !important;
  --rdp-accent-background-color: #f0f0f0;

}

.rdp-button_previous {
  background-color: rgb(250, 250, 250) !important;
}

.rdp-button_next {
  background-color: rgb(250, 250, 250) !important;
}

.rdp-day_button:hover {
  background-color: #f7fcff !important;
}

/* .rdp-day_button:hover {
  background-color: #149ae7 !important;
} */

.slick-slide {
  padding: -2rem !important;
}
@media (max-width: 1170px) {
  .slick-slide{
  padding-left: 0rem;
  margin-right: 0rem;
 display: flex;
 justify-content: center;
 align-items: center;
   /*width: 33rem; */
  /* background-color: aqua; */
}
}
@media (max-width: 790px) {
  .slick-slide{
  padding-left: 0rem;
  margin-right: 0rem;
 display: flex;
 justify-content: center;
 align-items: center;
   /*width: 33rem; */
  /* background-color: aqua; */
}
}