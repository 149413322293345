.Container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.main {
  width: 50rem;
  background-color: #333333;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.title {
  color: #8cc9e9;
  font-family: "GalanoGrotesque-SemiBold";
  font-size: 2.188rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.list {
  width: 60%;
  text-align: left;
}

.detail {
  font-size: 1.125rem;
  font-family: "GalanoGrotesque-Medium";
  color: white;
  display: flex;
  justify-content: center;
  text-align: center;
}

.detailPoints {
  font-size: 1.125rem;
  font-family: "GalanoGrotesque-Medium";
  color: white;
  /* text-align: center; */
  /* padding-left: 11rem; */
}

@media screen and (max-width: 768px) {
  .list {
    width: 80%;
    text-align: left;
  }
}

@media screen and (max-width: 480px) {
  .main{
  padding: 2rem 1.25rem;
  }
  .list {
    width: 100%;
  }

  .detail {
    /* Align text to left */
    white-space: pre-wrap;
    /* Preserve line breaks */
  }
}
