/* left */
.container{
    width: 100%;
    padding-top: 0rem;
    padding-bottom: 7rem;
    margin-top: 6rem;
    /* background-color: aqua; */
}

.box{
    border-radius: 10px;
    width: 70rem;
    height: 230px;
    background-color: #8CC9E9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 2rem;
}
.textArea{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:2rem;
    margin-left: 30rem;

}

.box h1{
color: white;
font-size: 10rem;
}

.box p{
font-family: 'GalanoGrotesque-Bold';
color: black;
text-align: center;
width: 15rem;
}

.img2{
    max-width: 15rem;
    max-height: auto;
    margin-left: -39px;

}
.img1{
    max-width: 17rem;
    max-height: auto;
    margin-left: 27px;
}
.img3{
    max-width: 16rem;
    max-height: auto;
    margin-left: 41px;
}
.img4{
    max-width: 16.5rem;
    max-height: auto;
    margin-left: -54px;

}

/* right */

.containerRight{
    width: 100%;
    padding-top: 7rem;
    padding-bottom: 7rem;
    display: flex;
    justify-content: right;
    margin-top: 6rem;
}

.boxRight{
    border-radius: 10px;

    width: 70rem;
    height: 230px;
    background-color: #8CC9E9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
}
.textAreaRight{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:2rem;
    margin-right: 28rem;
}


.boxRight h1{
    color: white;
    font-size: 10rem;
    }
    
.boxRight p{
    font-family: 'GalanoGrotesque-Bold';
    color: black;
    width: 15rem;
    text-align: center;
    }


    @media screen and (max-width:1130px){
        .box{
            width: 50rem;
            height: 200px;
        }
        .textArea{
            margin-left: 10rem;
        }
        .boxRight{
            width: 50rem;
            height: 200px;
            padding-left: 1.8rem;

        }
        .textAreaRight{
            margin-right: 10rem;
        }
        
      }
      @media screen and (max-width:820px){
        .box{
            width: 40rem;
            height: 170px;
            padding-right: 2rem;

        }
        .textArea{
            margin-left: 2rem;
        
        }
        .box h1{
            font-size: 7rem;
            }
        .boxRight{
            width: 40rem;
            height: 170px;
            padding-left: 2rem;
        }
        .textAreaRight{
            margin-right: 6rem;
        }
        
        .boxRight h1{
            font-size: 7rem;
            }
            .boxRight p{
                width: 13rem;
                }
      }
      @media screen and (max-width:680px){
      /* left */
      .container{
        margin-top: 0rem;
    }
.box{
    width: 30rem;
    height: 120px;
    padding-left: 5rem;
    padding-right: 2rem;
}
.textArea{
    margin-left: 0rem;

}

.box h1{
font-size: 6rem;
}

.box p{
width: 10rem;
font-size: .8rem;
}
.img1{
    max-width: 10rem;
    max-height: auto;
}

.img2{
    max-width: 10rem;
    max-height: auto;
}
.img3{
    max-width: 10rem;
    max-height: auto;
}

.img4{
    max-width: 10rem;
    max-height: auto;
}

/* right */
.containerRight{
    margin-top: 0rem;
}
.boxRight{
    width: 30rem;
    height: 120px;
    padding-right: 7rem;
}
.textAreaRight{
    margin-right: 0rem;
}
.boxRight h1{
    font-size: 6rem;
    }
    .textAreaRight{
        gap:1.5rem;
    }
.boxRight p{
    width: 10rem;
font-size: .8rem;
margin-left: 1rem;
    }

      }
      @media screen and (max-width:500px){
        /* left */
        .container{
            padding-top: 0rem;
            padding-bottom: 2rem;
            margin-top: 0rem;
        }
  .box{
      width: 100%;
      height: auto;
      /* padding-left: 0rem; */
      padding-right: 0rem;
      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      padding-left: 0rem;
      
  }
  .textArea{
    display: flex;
    flex-direction: column;
    gap: 0rem;
    margin-left: 0rem;
  }

  .box h1{
    font-size: 2rem;
    text-align: center;
    }
  .box p{
    text-align: center;
    }
  
  
  /* right */
  .containerRight{
    padding-top: 0rem;
    padding-bottom: 2rem;
    margin-top: 0rem;
  }
  .boxRight{
    width: 100%;
    height: auto;
    padding-left: 0rem;
    padding-right: 0rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content:center;
    align-items: center;

  }
  .textAreaRight{
    display: flex;
    flex-direction: column-reverse;
    gap: 0rem;
    margin-right: 0rem;

  }

  .boxRight h1{
      font-size: 2rem;
    text-align: center;
      }
      
  .boxRight p{
    text-align: center;
    margin-left: 0rem;
      }

      .img1{
        margin-left: 0px;

    }
    
    .img2{
        margin-left: 0px;

    }
    .img3{
        margin-left: 0px;

    }
    
    .img4{
        margin-left: 0px;
    }
  
        }